import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Pagination } from 'swiper/modules';
import { useEffect, useState } from 'react';
import { db } from '../../firebaseConfig';
import { useTranslation } from 'react-i18next';

const News = () => {
  const [newsDataRu, setNewsDataRU] = useState([]);
  const [newsDataKg, setNewsDataKg] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    const unsubscribeRu = db.collection('newsDataRu').onSnapshot((snapshot) => {
      const newsDataListRu = [];
      snapshot.forEach(async (doc) => {
        newsDataListRu.push({ id: doc.id, ...doc.data() });
      });
      setNewsDataRU(newsDataListRu);
    });

    const unsubscribeKg = db.collection('newsDataKg').onSnapshot((snapshot) => {
      const newsDataListKg = [];
      snapshot.forEach(async (doc) => {
        newsDataListKg.push({ id: doc.id, ...doc.data() });
      });
      setNewsDataKg(newsDataListKg);
    });

    return async () => {
      unsubscribeRu();
      unsubscribeKg();
    };
  }, []);
  useEffect(() => {
    const currentLanguage = i18n.language;
    const updatedNewsData = currentLanguage === 'ru' ? newsDataRu : newsDataKg;
    setNewsData(updatedNewsData);
  }, [i18n.language]);
  useEffect(() => {
    const currentLanguage = i18n.language;
    const updatedNewsData = currentLanguage === 'kg' ? newsDataKg : newsDataRu;
    setNewsData(updatedNewsData);
  }, [i18n.language]);


  
  const breakpoints = {
    1440:{
      slidesPerView: 1.8
    },
    1280:{
      slidesPerView: 1.6,
    },
    1024: {
      slidesPerView: 1.3,
    },
    768: {
      slidesPerView: 0.9,
    },
    640:{
      slidesPerView: 0.8,
    },
    425:{
      slidesPerView: 1,
    },
    375:{
      slidesPerView: 0.9,
    },
    320:{
      slidesPerView: 0.8,
    }
  };
  return (
    <div>
        <Swiper
            slidesPerView={1.8}
            spaceBetween={30}
            freeMode={true}
            modules={[FreeMode, Pagination]}
            className="w-full h-full"
            breakpoints={breakpoints}
            pagination={{
              dynamicBullets: true,
            }}
        >
          {newsData.map((item) => (
            <SwiperSlide key={item.id}>
              <div data-aos="fade-right" className="flex w-[700px] max-h-[367px] p-6 rounded-xl shadow-lg hover:border-[#FF4012] transition border gap-10 ml:flex-col mm:flex-col ms:flex-col ml:w-[355px] ml:max-h-[700px] mm:w-[330px] mm:max-h-[700px] ms:w-[280px] ms:max-h-[700px]">
                <div className="flex w-[50%] flex-col justify-between ml:w-[100%] mm:w-[100%] ms:w-[100%]">
                  <h1 className="font-bold text-lg">{item.h1}</h1>
                  <p className="text-xm">{item.p1}</p>
                  <img src={item.img ? item.img : "Неизвество"} alt="img" className="rounded-xl max-w-[330px] max-h-[183px] object-cover" />
                  <p className="text-xs text-gray-500">{item.submittedAt ? item.submittedAt.toDate().toLocaleDateString() : 'N/A'}</p>
                </div>
                <div className="flex w-[50%] flex-col gap-10 ml:w-[100%] mm:w-[100%] ms:w-[100%]">
                  <h1 className="font-bold text-md">{item.h2}</h1>
                  <p className="text-base">{item.p2}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  )
}

export default News