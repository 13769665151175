import React, { useRef, useState } from 'react';
import { db } from '../../firebaseConfig';
import ReCAPTCHA from 'react-google-recaptcha';
import {useTranslation} from 'react-i18next';
import { Alert, Snackbar } from '@mui/material';

const ModalContact = () => {
  const {t} = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');
  const [isCaptchaVisible, setIsCaptchaVisible] = useState(false);
  const [isCaptchaPassed, setIsCaptchaPassed] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const recaptchaRef = useRef();

  const handleFormSubmitAttempt = () => {
    if (!isCaptchaVisible) {
      // Если капча не видима, то показываем её
      setIsCaptchaVisible(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen2(false);
  };

  const handleSubmit = async () => {
    try {
      if (isCaptchaPassed) {
        const currentDate = new Date();
        await db.collection('contactData').add({
          name,
          email,
          text,
          submittedAt: currentDate
        });

        // Очистка полей после успешной отправки
        setName('');
        setEmail('');
        setText('');
        setIsCaptchaPassed(false);
        setIsFormSubmitted(true); // Устанавливаем флаг успешной отправки
        // Сброс капчи

        recaptchaRef.current.reset();
        setOpen(true);
        setTimeout(() => handleClose(), 3000)
      } else {
        setOpen2(true);
        setTimeout(() => handleClose2(), 3000)
        console.log('Капча не пройдена');
      }
    } catch (e) {
      console.log('Submit error:', e);
    }
  };

  return (
    <div className="w-[346px] mm:w-[320px] ms:w-[300px] bg-white border rounded-lg flex flex-col gap-5 px-5 py-5">
      <h1 className="text-xl font-bold">{t('admin.modal1')}</h1>
      <div className="flex flex-col gap-2 text-gray-500">
        <label htmlFor="text2">{t('admin.name')}</label>
        <input
          name="text2"
          required
          className="border rounded-xl h-9 border-t-0.5 border-gray-300"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label htmlFor="email">Эл. почта</label>
        <input
          required
          name="email"
          className="border rounded-xl h-9 border-t-0.5 border-gray-300"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="text">{t('admin.message')}</label>
        <textarea
          style={{ resize: 'none' }}
          required
          name="text"
          className="border rounded-xl h-44 border-t-0.5 border-gray-300 items-start"
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </div>
      {isCaptchaVisible && (
        <ReCAPTCHA
          sitekey="6LeYBfEnAAAAAJ-OfbIQ4VaN9mjsjwyFtrilVj6p"
          ref={recaptchaRef}
          onChange={(value) => setIsCaptchaPassed(!!value)}
        />
      )}
      <button
        type="button"
        className={`items-center rounded-md bg-[#FF4012] py-3 text-sm font-semibold text-white shadow-sm hover:bg-red-500 ${isFormSubmitted ? 'cursor-not-allowed opacity-50' : ''}`}
        onClick={isFormSubmitted ? null : (isCaptchaVisible ? handleSubmit : handleFormSubmitAttempt)}
        disabled={isFormSubmitted}
      >
        {isFormSubmitted ? t('admin.send1') : t('admin.send2')}
      </button>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Успешно отправлено!
        </Alert>
      </Snackbar>
      <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
        <Alert onClose={handleClose2} severity="error" sx={{ width: '100%' }}>
          Ошибка отправки!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ModalContact;








