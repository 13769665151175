import QuizCom2 from './QuizCom2';
import BlockEnd from '../BlockEnd';
import { useTranslation } from 'react-i18next';

const QuizCom1 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="px-10 mm:px-5 ms:px-5 ml:px-5 py-3 flex flex-col gap-5">
        <h1 className="font-bold text-2xl">{t('quiz.text')}</h1>
        <h2 className="font-bold text-xl">{t('quiz.text2')}</h2>
        <div className="flex items-center justify-around max-w-[725px] md:max-w-[600px] px-6 py-4 text-white ml-[-40px] rounded-tr-[500px] rounded-br-[500px] bg-gradient-custom mb-[80px]">
          <h1 className="font-bold text-2xl mm:hidden ml:hidden ms:hidden">
            {t('quiz.text3')}
          </h1>
          <img
            src="/assets/logo-white.svg"
            width={113}
            height={113}
            alt="logo"
          />
          <div className="">
            <img
              src="/assets/enot-text.svg"
              width={150}
              height={100}
              alt="text logo"
            />
            <h2 className="font-bold text-2xl sm:text-xl mm:text-lg ml:text-lg ms:text-lg mt-5">
              {t('quiz.text4')}
            </h2>
          </div>
        </div>
        <QuizCom2 />
      </div>
      <BlockEnd />
    </>
  );
};

export default QuizCom1;
