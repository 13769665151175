import {useTranslation} from 'react-i18next';
import { Menu } from '@mui/base/Menu';
import { MenuButton } from '@mui/base/MenuButton';
import { MenuItem } from '@mui/base/MenuItem';
import { Dropdown } from '@mui/base/Dropdown';
import MenuBar from './Menu';
import { Link } from 'react-router-dom';

const Header = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className="flex w-auto justify-between items-center h-[100px] ml:h-[80px] mm:px-[20px] mm:py-[15px] ml:py-[18px] ml:px-[25px] ms:px-[15px] ms:py-[10px] px-[45px] py-[28px] z-100">
      <Link to="/">
        <img src="/assets/logo.svg" width={110} height={70} alt='logo'/>
      </Link>
      <nav className="flex gap-28 lg:gap-20 md:gap-8 z-10 sm:hidden mm:hidden ml:hidden ms:hidden">
          <Link to="/product" className="hover:text-[#FF4012]">{t('element.product')}</Link>
          <Link to="/quiz" className="hover:text-[#FF4012]">{t('element.quiz')}</Link>
          <Link to="/info" className="hover:text-[#FF4012]">{t('element.info')}</Link>
          <Link to="/about" className="hover:text-[#FF4012]">{t('element.about')}</Link>
      </nav>
      <div className="relative flex gap-2 sm:hidden mm:hidden ms:hidden ml:hidden">
        <Dropdown>
          <MenuButton>
            <img src="/assets/lang.svg" width={50} height={50} alt='lang' />
          </MenuButton>
          <Menu className="bg-gray-800 text-white p-4 rounded-xl font-bold z-50">
            <MenuItem className="flex items-center gap-1 mb-1">
              <img width="48" height="48" src="https://img.icons8.com/color/96/russian-federation-circular.png" alt="russian-federation-circular" className="w-[30px]"/>
              <Link onClick={() => changeLanguage('ru')} value="ru" className="hover:text-[#FF4012]">{t('element.ru')}</Link>
            </MenuItem>
            <MenuItem className="flex items-center gap-1">
              <img width="48" height="48" src="https://img.icons8.com/color/96/kyrgyzstan-circular.png" alt="kyrgyzstan-circular" className="w-[30px]" />
              <Link onClick={() => changeLanguage('kg')} className="hover:text-[#FF4012]">{t('element.kg')}</Link>
            </MenuItem>
          </Menu>
        </Dropdown>
        <a target='_blank' to="https://www.instagram.com/ubr.kg/" className="text-white text-base border-none transition duration-300 ease-in-out transform flex items-center justify-center">
          <img src="/assets/inst.svg" width={50} height={50} alt='inst' />
        </a>
      </div>
      <MenuBar lassName="hidden sm:block" />
    </header>
  )
}

export default Header