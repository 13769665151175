import Footer from "../components/Footer"
import Header from "../components/Header"
import AboutCom1 from "../components/about/AboutCom1"


const AboutPage = () => {
  return (
    <>
      <Header />
      <AboutCom1 />
      <Footer />
    </>
  )
}

export default AboutPage