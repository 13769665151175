import Footer from "../components/Footer"
import Header from "../components/Header"
import QuizCom1 from "../components/quiz/QuizCom1"


const QuizPage = () => {
  return (
    <>
      <Header />
      <QuizCom1 />
      <Footer />
    </>
  )
}

export default QuizPage
