import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { Fragment, useState } from 'react';
import {useTranslation} from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';

export default function MenuBar() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 210 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="text-lg font-semibold">
            <ListItem>
                <ListItemButton>
                    <Link to="/product" className="hover:text-[#FF4012]">{t('element.product')}</Link>
                </ListItemButton>
            </ListItem>
            <ListItem>
                <ListItemButton>
                    <Link to="/quiz" className="hover:text-[#FF4012]">{t('element.quiz')}</Link>
                </ListItemButton>
            </ListItem>
            <ListItem>
                <ListItemButton>
                    <Link to="/info" className="hover:text-[#FF4012]">{t('element.info')}</Link>
                </ListItemButton>
            </ListItem>
            <ListItem>
                <ListItemButton>
                    <Link to="/about" className="hover:text-[#FF4012]">{t('element.about')}</Link>
                </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem>
                <ListItemButton>
                    <a target='_blank' href="https://www.instagram.com/ubr.kg/">Instagram</a>
                </ListItemButton>
            </ListItem>
            <ListItem>
                <ListItemButton className="flex gap-2">
                    <Link onClick={() => changeLanguage('ru')}>
                      <img width="48" height="48" src="https://img.icons8.com/color/96/russian-federation-circular.png" alt="russian-federation-circular" className="w-[40px]"/>              
                    </Link>
                    <h1 className="text-xl">|</h1>
                    <Link onClick={() => changeLanguage('kg')}>
                      <img width="48" height="48" src="https://img.icons8.com/color/96/kyrgyzstan-circular.png" alt="kyrgyzstan-circular" className="w-[40px]" />
                    </Link>
                </ListItemButton>
            </ListItem>
      </List>
    </Box>
  );

  return (
    <div className="hidden sm:block mm:block ml:block ms:block">
      {['right'].map((anchor) => (
        <Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}><MenuIcon className="text-black text-4xl"/></Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </Fragment>
      ))}
    </div>
  );
}