import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { db } from '../../firebaseConfig';
import Papa from 'papaparse';
import { Alert, Snackbar } from '@mui/material';

export default function TableList() {
  const [formData, setFormData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  // Стягивание
   useEffect(() => {
    const unsubscribe = db.collection('formData').onSnapshot((snapshot) => {
      const formDataList = [];
      snapshot.forEach(async(doc) => {
        formDataList.push({ id: doc.id, ...doc.data() });
      });
      setFormData(formDataList);
    });

    return async () => unsubscribe();
  }, []);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen2(false);
  };
  // Удаление из списка
  const handleDelete = async (id) => {
    try {
      await db.collection('formData').doc(id).delete();
      setOpen(true);
      setTimeout(() => handleClose(), 3000)
    } catch (error) {
      setOpen2(true);
      setTimeout(() => handleClose2(), 3000)
      console.error('Delete error:', error);
    }
  };
  // Фильтрация
  const filteredData = formData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  // Экспорт в excel(csv)
  const handleExportCSV = () => {
    const csvData = filteredData.map((item) => ({
      ...item,
      ecp: `${item.ecp.quantity} ${item.ecp.deadline}`, 
      jacarta: `${item.jacarta.quantity} ${item.jacarta.deadline}`,
      kkm: `${item.kkm.quantity} ${item.kkm.deadline}`,
      osago: `${item.osago.quantity} ${item.osago.deadline}`,
      reports: `${item.reports.quantity} ${item.reports.deadline}`,
      rutoken: `${item.rutoken.quantity} ${item.rutoken.deadline}`,
      time: item.submittedAt.toDate().toLocaleString()
    }));
  
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'ubr-data.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <TableContainer component={Paper} className="p-5">
      <div className="flex h-10 gap-4">
        <input 
          value={searchQuery} 
          onChange={(e) => setSearchQuery(e.target.value)}
          className="max-w-[440px] border rounded-xl p-2"
          placeholder="Поиск..."
        />
        <button onClick={handleExportCSV} title="Скачать данные в Exsel">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#29883a" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
          </svg>
          <p className="text-[#29883a]">CSV</p>
        </button>
      </div>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Ф.И.О.</TableCell>
            <TableCell align="center">Телефон&nbsp;(g)</TableCell>
            <TableCell align="center">ECP&nbsp;(g)</TableCell>
            <TableCell align="center">Jacarta&nbsp;(g)</TableCell>
            <TableCell align="center">KKM</TableCell>
            <TableCell align="center">Osago</TableCell>
            <TableCell align="center">reports</TableCell>
            <TableCell align="center">rutoken</TableCell>
            <TableCell align="center">Дата/Время</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((item) => (
            <TableRow
              key={item.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">{item.fio ? item.fio : "Неизвество"}</TableCell>
              <TableCell align="left">
                {item.phone ? item.phone : "Неизвество"}
              </TableCell>
              <TableCell align="left">
                {`${item.ecp.quantity ? item.ecp.quantity : '0'} / ${item.ecp.deadline ? item.ecp.deadline : '0'}`}
              </TableCell>
              <TableCell align="left">
                {`${item.jacarta.quantity ? item.jacarta.quantity : '0'} / ${item.jacarta.deadline ? item.jacarta.deadline : '0'}`}
              </TableCell>
              <TableCell align="left">
                {`${item.kkm.quantity ? item.kkm.quantity : '0'} / ${item.kkm.deadline ? item.kkm.deadline : '0'}`}
              </TableCell>
              <TableCell align="left">
                {`${item.osago.quantity ? item.osago.quantity : '0'} / ${item.osago.deadline ? item.osago.deadline : '0'}`}
              </TableCell>
              <TableCell align="left" >
                {`${item.reports.quantity ? item.reports.quantity : '0'} / ${item.reports.deadline ? item.reports.deadline : '0'}`}
              </TableCell>
              <TableCell align="left">
                {`${item.rutoken.quantity ? item.rutoken.quantity : '0'} / ${item.rutoken.deadline ? item.rutoken.deadline : '0'}`}
              </TableCell>
              <TableCell align="left">
                {item.submittedAt ? item.submittedAt.toDate().toLocaleString() : 'N/A'}
              </TableCell>
              <TableCell align="left">
                <button variant="danger" onClick={() => handleDelete(item.id)} title="Удалить">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                  </svg>
                </button>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Успешно удалено!
                  </Alert>
                </Snackbar>
                <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
                  <Alert onClose={handleClose2} severity="error" sx={{ width: '100%' }}>
                    Ошибка удаление!
                  </Alert>
                </Snackbar>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}