import { db } from '../../firebaseConfig';
import { useEffect, useState } from 'react';
import Papa from 'papaparse'; 
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Paper from '@mui/material/Paper';
import { TextareaAutosize } from '@mui/base';
import { Alert, Snackbar } from '@mui/material';

const ContactList = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [conData, setConData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  // Стягивание
   useEffect(() => {
    const unsubscribe = db.collection('contactData').onSnapshot((snapshot) => {
      const conDataList = [];
      snapshot.forEach(async(doc) => {
        conDataList.push({ id: doc.id, ...doc.data() });
      });
      setConData(conDataList);
    });

    return async () => unsubscribe();
  }, []);
  // Удаление из списка
  const handleDelete = async (id) => {
    try {
      await db.collection('contactData').doc(id).delete();
      setOpen(true);
      setTimeout(() => handleClose(), 3000)
    } catch (error) {
      setOpen2(true);
      setTimeout(() => handleClose2(), 3000)
      console.error('Delete error:', error);
    }
  };
  // Фильтрация
  const filteredData = conData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen2(false);
  };
  // Экспорт в excel(csv)
  const handleExportCSV = () => {
    const csvData = filteredData.map((item) => ({
      ...item,
      time: item.submittedAt.toDate().toLocaleString()
    }));
  
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'ubr-contact.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <TableContainer component={Paper}  className="p-5">
      <div className="flex h-10 gap-4">
        <input 
          value={searchQuery} 
          onChange={(e) => setSearchQuery(e.target.value)}
          className="max-w-[440px] border rounded-xl p-2"
          placeholder="Поиск..."
        />
        <button onClick={handleExportCSV} title="Скачать данные в Exsel">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#29883a" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
          </svg>
          <p className="text-[#29883a]">CSV</p>
        </button>
      </div>
      <Table className="mt-5">
        <TableHead>
          <TableRow>
            <TableCell align="left">Ф.И.О.</TableCell>
            <TableCell align="left">Эл. почта</TableCell>
            <TableCell align="left">Сообщение</TableCell>
            <TableCell align="left">Время</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.name ? item.name : "Неизвество"}</TableCell>
              <TableCell>
                {item.email ? item.email : "Неизвество"}
              </TableCell>
              <TableCell fullWidth>
                <TextareaAutosize
                >
                  {item.text ? item.text : "Неизвество"}
                </TextareaAutosize>
              </TableCell>
              <TableCell>
                {item.submittedAt ? item.submittedAt.toDate().toLocaleString() : 'N/A'}
              </TableCell>
              <TableCell>
                <button variant="danger" onClick={() => handleDelete(item.id)} title="Удалить">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                  </svg>
                </button>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Успешно удалено
                  </Alert>
                </Snackbar>
                <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
                  <Alert onClose={handleClose2} severity="error" sx={{ width: '100%' }}>
                    Ошибка удаление
                  </Alert>
                </Snackbar>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContactList;
