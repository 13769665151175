import { useTranslation } from 'react-i18next';

const WhyWe = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="text-[24px] font-bold">{t('main.t29')}</h1>
      <div className="ms:mt-[140px] mm:mt-[140px] ml:mt-[140px] sm:mt-[130px] flex justify-evenly">
        <div className="ms:gap-[10px] sm:gap-[10px] md:gap-[20px] flex flex-col gap-[50px] mt-[34px]">
          <div className="ms:w-[140px] hover:border-[#FF4012] hover:scale-105 transition border mm:w-[160px] ml:w-[180px] sm:w-[220px] md:max-w-[293px] lg:max-w-[300px] flex items-center bg-[#222222] w-[408px] h-[226px] rounded-[10px]">
            <img
              src="/assets/electrootchet.svg"
              alt="electrootchet"
              className="ms:hidden mm:hidden ml:hidden sm:hidden min-w-[96px] min-h-[146px] mx-[16px] my-[40px]"
            />
            <div className="ms:p-2 mm:p-2 ml:p-2 sm:p-3 flex flex-col">
              <h1 className="ms:text-[10px] mm:text-[10px] ml:text-[12px] sm:text-[20px] text-[20px] text-white font-bold">
                {t('main.t30')}
              </h1>
              <span className="ms:text-[12px] mm:text-[14px] ml:text-[14px] sm:text-[16px] md:w-25 md:text-[12px] lg:text-[12px] text-[16px] text-white">
                {t('main.t31')}
              </span>
            </div>
          </div>
          <div className="ms:w-[140px] hover:border-[#FF4012] hover:scale-105 transition border mm:w-[160px] ml:max-w-[180px] sm:w-[220px] md:max-w-[293px] lg:max-w-[300px] flex items-center bg-[#222222] w-[408px] h-[226px] rounded-[10px]">
            <img
              src="/assets/bezop.svg"
              alt="electrootchet"
              className="ms:hidden mm:hidden ml:hidden sm:hidden min-w-[96px] min-h-[146px] mx-[16px] my-[40px]"
            />
            <div className="ms:p-2 mm:p-2 ml:p-2 sm:p-3 flex flex-col">
              <h1 className="ms:text-[10px] mm:text-[10px] ml:text-[12px] sm:text-[20px] text-[20px] text-white font-bold">
                {t('main.t17')}
              </h1>
              <span className="ms:text-[12px] ms:w-32 mm:w-32 mm:text-[12px] ml:text-[14px] ml:w-36 sm:text-[16px] md:w-24 md:text-[11px] lg:text-[12px] lg:w-40 text-[16px] text-white w-56">
                {t('main.t32')}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col relative">
          <img
            src="/assets/enotwhywe.svg"
            alt="enotwhywe"
            className="ms:hidden sm:hidden lg:visible mm:hidden ml:hidden md:hidden lg:min-w-[130%] lg:-translate-x-[11%] lg:translate-y-[30%]  z-20 min-w-[140%] min-h-max absolute transform -translate-x-[14%] translate-y-[23%]"
          />
          <img
            src="/assets/whyenot.svg"
            alt="enotwhywe"
            className="ms:hidden sm:hidden md:visible lg:hidden xl:hidden mm:hidden ml:hidden md:min-w-[390%] md:min-h-[100%] z-20  absolute transform -translate-x-[35%] translate-y-[26%]"
          />
          <img
            src="/assets/enotsm.svg"
            alt="enotwhywe"
            className="ms:visible mm:visible ml:visible sm:visible md:hidden lg:hidden xl:hidden ms:min-w-[600%] ms:-translate-x-[40%] ms:-translate-y-[75%] mm:min-w-[600%] mm:-translate-x-[42%] mm:-translate-y-[75%] ml:min-w-[600%] ml:-translate-x-[42%] ml:-translate-y-[75%] sm:min-w-[600%] sm:-translate-x-[42%] sm:-translate-y-[75%]  md:min-w-[390%] md:min-h-[100%] z-20  absolute transform -translate-x-[35%] translate-y-[26%]"
          />
          <div className="relative p-5">
            <div className="ms:hidden mm:hidden ml:hidden hover:border-[#FF4012] hover:scale-105 transition border sm:hidden md:hidden lg:w-[200px] relative z-10 w-[298px] h-[105px] flex flex-col justify-center items-center border bg-white mt-[170%] rounded-[10px] ">
              <p className="ms:hidden mm:hidden ml:hidden sm:hidden md:hidden lg:w-[170px] lg:text-[14px] w-[190px] text-[16px] font-bold">
                {t('main.t33')}
              </p>
              <span className="ms:hidden mm:hidden ml:hidden sm:hidden md:hidden lg:text-[16px] lg:w-[180px] w-[200px] text-[24px] font-bold">
                (0312) 98 68 66
              </span>
            </div>
            <img
              src="/assets/btntriugol.svg"
              alt="btntriugolleft"
              className="ms:hidden mm:hidden ml:hidden sm:hidden md:hidden lg:w-10 lg:bottom-[95px] lg:right-3 absolute bottom-[70px] right-0 -z-10 w-30"
            />
            <img
              src="/assets/btntriugolrigth.svg"
              alt="btntriugolrigth"
              className="ms:hidden mm:hidden ml:hidden sm:hidden md:hidden lg:w-10 lg:bottom-3 lg:left-3 absolute bottom-0 left-0 -z-10 w-30"
            />
          </div>
        </div>
        <div className="ms:gap-[10px] sm:gap-[10px] md:gap-[20px] flex flex-col gap-[50px] mt-[34px]">
          <div className="ms:w-[140px] hover:border-[#FF4012] hover:scale-105 transition border mm:w-[160px] ml:w-[180px] sm:w-[220px] md:max-w-[293px] lg:max-w-[300px] flex items-center bg-[#222222] w-[408px] h-[226px] rounded-[10px]">
            <img
              src="/assets/econom.svg"
              alt="electrootchet"
              className="ms:hidden mm:hidden ml:hidden sm:hidden min-w-[96px] min-h-[146px] mx-[16px] my-[40px]"
            />
            <div className="ms:p-2 mm:p-2 ml:p-2 sm:p-3 flex flex-col">
              <h1 className="ms:text-[10px] mm:text-[10px] ml:text-[12px] text-[20px] text-white font-bold">
                {t('main.t34')}
              </h1>
              <span className="ms:text-[10px] mm:text-[10px] ml:text-[12px] md:text-[10px] lg:text-[12px] lg: text-[16px] text-white">
                {t('main.t35')}
              </span>
            </div>
          </div>
          <div className="ms:w-[140px] hover:border-[#FF4012] hover:scale-105 transition border mm:w-[160px] ml:w-[180px] sm:w-[220px] md:max-w-[293px] lg:max-w-[300px] flex items-center bg-[#222222] w-[408px] h-[226px] rounded-[10px]">
            <img
              src="/assets/tehpod.svg"
              alt="electrootchet"
              className="ms:hidden mm:hidden ml:hidden sm:hidden min-w-[96px] min-h-[146px] mx-[16px] my-[40px]"
            />
            <div className="ms:p-2 mm:p-2 ml:p-2 sm:p-3 flex flex-col">
              <h1 className="ms:text-[10px] mm:text-[10px] ml:text-[12px] text-[20px] text-white font-bold">
                {t('main.t36')}
              </h1>
              <span className=" ms:text-[12px] mm:text-[12px]  ml:text-[12px] lg:text-[12px] text-[16px] text-white">
                {t('main.t37')}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="ms:visible mm:visible ml:visibles sm:hidden md:visible  lg:hidden xl:hidden  relative flex justify-center p-5">
        <div className="ms:mt-1 mm:mt-0 ml:mt-0 md:mt-0 lg:w-[200px] relative z-10 w-[298px] h-[105px] flex flex-col justify-center items-center border bg-white mt-[175%] rounded-[10px] ">
          <p className="lg:w-[170px] lg:text-[14px] w-[190px] text-[16px] font-bold">
            {t('main.t38')}
          </p>
          <span className="lg:text-[16px] lg:w-[180px] w-[200px] text-[24px] font-bold">
            <a href="tel:312986866">(0312) 98 68 66</a>
          </span>
        </div>
        <img
          src="/assets/btntriugol.svg"
          alt="btntriugolleft"
          className="md:w-10 md:bottom-[95px] md:left-[67%] absolute bottom-[70px] right-0 -z-10 w-30"
        />
        <img
          src="/assets/btntriugolrigth.svg"
          alt="btntriugolrigth"
          className="md:w-10 md:bottom-[10px] md:left-[29%] absolute bottom-0 left-0 -z-10 w-30"
        />
      </div>
    </>
  );
};

export default WhyWe;
