import { useEffect, useState } from 'react';
import {auth} from '../../firebaseConfig';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ContactList from './ContactList';
import TableList from './TableList';
import NewsList from './NewsList';
import {useTranslation} from 'react-i18next';
import { useNavigate } from 'react-router-dom';



const AdminCom = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  // Проверка вошел ли админ или если не вошел то выводится страница /login
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate('/login');
      }
    });
  return () => unsubscribe()});

  // Выход из аккаунта
  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="p-4 mx-auto max-w-7xl min-h-[80vh]">
      <button onClick={handleLogout} className="flex ml-auto border px-5 py-2 rounded-lg bg-[#343434] text-white" title={t('admin.outTitle')}>
        {t('admin.out')}
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
        </svg>
      </button>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t('admin.order')} value="1" />
              <Tab label={t('admin.ques')} value="2" />
              <Tab label={t('admin.news')} value="3" />
            </TabList>
          </Box>
          <TabPanel value="1"><TableList/></TabPanel>
          <TabPanel value="2"><ContactList/></TabPanel>
          <TabPanel value="3"><NewsList/></TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default AdminCom;
