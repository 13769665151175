import Calculator from '../admin/Calculator';
import ServicesCom from './ServicesCom';
import WhyWe from './WhyWe';
import Univers from './Univers';
import TableMap from './TableMap';
import {useTranslation} from 'react-i18next';
import News from '../info/News';
import Header from '../Header';
import Footer from '../Footer';

const Home = () => {
  const {t} = useTranslation();
  return (
    <>
    <Header />
      <div className="ms:h-[450px] mm:h-[450px] sm:h-[360px] md:h-[360px] ms:flex-col mm:flex-col mm:gap-0 ms:gap-0 ml:gap-0 ml:flex-col ml:h-[450px] ml:relative sm:gap-10 md:gap-10 realitive flex w-auto h-[310px] bg-[url('/assets/bg.svg')] bg-cover bg-center gap-44 justify-between">
        <img
          src="/assets/orange.svg"
          width={470}
          height={200}
          alt="orange"
          className="ms:-left-[10%] ms:top-20 mm:-left-[10%] mm:top-20 ml:-left-32 ml:top-0 ml:w-[620px] absolute top-12 z-1"
        />
        <h1 className="ms:pl-1 ms:ms-pt3 md:w-[70%] ms:text-base mm:pl-2 mm:mt-10 ml:mt-12 mm:pt-5 mm:text-lg ml:pl-2 ml:pt-8 ml:text-xl pl-12 pt-16 text-2xl text-white z-10 font-bold">
          {t('main.title')}
        </h1>
        <div className="ms:translate-x-[10%] py-2 ms:z-10 ms:mr-0 mm:translate-x-[25%] z-10  mm:z-10 mm:mr-0 ml:mr-0 ml:translate-x-[25%] ml:translate-y-0 ml:z-20 sm:z-10 sm:min-w-[150px] md:min-w-[300px] flex flex-col justify-center items-center xl:w-[400px] ml:w-[300px] mm:w-[250px] ms:w-[270px] lg:w-[550px] h-[100%] text-center gap-1 mr-[13%]">
          <img
            src="/assets/enot-text.svg"
            width={63}
            height={20}
            alt="enot-text"
            className="ml:w-[50px]"
          />
          <img
            src="/assets/logo-white.svg"
            width={115}
            height={115}
            alt="logo-white"
            className=""
          />
          <h1 className="text-xl text-white font-semibold">
            {t('main.t1')}
          </h1>
          <a target='_blank' href="https://enot.ubr.kg/statements">
            <button className="px-11 py-3 bg-[#FF4012] rounded-xl text-white">
              {t('main.t2')}
            </button>
          </a>
        </div>
      </div>
      <div className="ms:px-2 lg:px-0 sm:px-0 mm:px-0 ml:px-0 md:px-0 px-11 py-20 w-auto h-full">
        <h1 className="ms:px-2 px-11 font-bold text-[24px] text-black">
          {t('main.t3')}
        </h1>
        <div className="flex gap-2">
          <p className="pl-11 font-bold">{t('main.t4')} -</p>
          <img src="/assets/geotag.svg" alt="geotag" />
        </div>
        <TableMap />
      </div>
      <div className="ms:px-2 ml:px-4 mm:px-3  px-11 py-20 w-auto h-full">
        <Calculator />
      </div>
      <div>
        <div className="ms:w-[310px] ms:px-[5px] ms:h-[190px] mm:w-[360px] mm:h-[170px] mm:px-[5px] ml:w-[400px] ml:h-[160px] ml:px-[5px] sm:w-[500px] sm:min-h-[180px] sm:pt-[30px] sm:px-[5px] md:px-[10px] md:pt-[10px] md:w-[720px] md:max-h-[105px] md:py-[5px] lg:w-[900px] lg:py-[10px] lg:px-[30px] bg-orange-500  w-[1200px] h-[92px]  py-[20px] px-[45px] inline-block rounded-l-[0px] rounded-r-[99px] bg-gradient-to-r from-red-500 via-pink-500 to-transparent mix-blend-multiply shadow-sm">
          <span className="md:text-[14px] ms:text-[12px] ml:text-[13px] mm:text-[12px] lg:text-[14px] text-white text-[16px] font-bold">
            {t('main.t5')}
          </span>
        </div>
      </div>
      <div className="flex justify-center my-[34px]">
        <button className=" ">
          <a
            target="_blank"
            href="https://enot.ubr.kg/statements"
            className="bg-[#FF4012] px-[45px] py-[15px] text-[16px] text-white font-bold rounded-[10px]"
          >
            {t('main.t6')}
          </a>
        </button>
      </div>
      <div className="ms:px-2 md:px-2 px-11">
        <h1 className="font-bold text-2xl my-10">{t('main.t388')}</h1>
        <News />
      </div>
      <div className="md:px-0  px-11 py-20 w-auto h-full">
        <ServicesCom />
      </div>
      <div className="ms:px-2 md:px-2 px-11 py-20 w-auto h-full">
        <WhyWe />
      </div>
      <div className="ms:px-2 px-11 py-20 w-auto h-full">
        <Univers />
      </div>
      <div className="relative mt-[220px]">
        <img
          className="ms:-translate-y-[90%] ms:translate-x-[0%] mm:-translate-y-[85%] ml:-translate-y-[90%] sm:-translate-y-[90%] md:-translate-y-[80%] absolute transform translate-x-[0%] -translate-y-[75%]"
          src="/assets/leftbottomvector.svg"
          alt="Вектор за формой снизу"
        />
        <div className="ms:w-[250px] ms:h-[210px] ms:-translate-y-[90%] mm:-translate-y-[100%] mm:w-[250px]  mm:h-[230px] ml:w-[250px] ml:h-[230px] ml:-translate-y-[100%] sm:-translate-y-[100%] md:-translate-y-[80%] absolute transform translate-x-[10%] -translate-y-[75%] w-[350px] h-[289px] rounded-[10px] bg-[#222222]">
          <div className="flex justify-center items-center flex-col">
            <div className="mm:p-[20px] ml:p-[10px] flex flex-col p-[30px] gap-[12px]">
              <span className="ms:text-[14px] mm:text-[14px] ml:text-[16px] text-[20px] font-bold text-white text-center">
                {t('main.t7')}
              </span>
              <span className="ms:justify-center ms:flex ms:text-[14px] mm:pb-8 mm:text-[16px] mm:flex mm:justify-center ml:pb-8 ml:text-[20px] ml:flex ml:justify-center text-[32px] text-white">
                <a href="tel:+96312986866">
                  (0312) 98 68 66
                </a>
              </span>
            </div>
            <div className="bg-[#FF4012] w-[185px] h-[49px] rounded-[10px] flex justify-center items-center ">
              <a
                href="https://enot.ubr.kg/statements"
                target="_blank"
                className="cursor-pointer text-[16px] text-white "
              >
                {t('main.t8')}
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;