import {useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';

const BlockEnd = () => {
  const {t} = useTranslation();
  return (
    <div className="flex">
        <div className="flex p-[30px] max-w-[530px] ml:w-[270px] mm:w-[260px] ms:w-[260px] ms:ml-7 h-[300px] bg-[#222] rounded-xl text-white z-10 mt-28 md:mt-32 ml-11">
          <div className="flex flex-col justify-between">
            <h1 className="text-xl font-bold">{t('element.end1')}</h1>
            <p className="font-bold text-2xl">(0312) 98 68 66</p>
            <Link href="/about" className="px-11 py-3 bg-[#FF4012] rounded-xl text-white w-[200px]">{t('element.end2')}</Link>
          </div>
          <div className="items-center ml:hidden mm:hidden ms:hidden">
            <img src="/assets/enot-text.svg" width={139} height={36} alt='logo' className="mt-10 mb-5"/>
            <img src="/assets/logo-white.svg" width={170} height={170} alt='logo'/>
          </div>
        </div>
        <img src="/assets/orange.svg" width={680} height={200} alt='orange' className="z-1 absolute items-start mt-12 ml:mt-44 mm:mt-52 ms:mt-56"/>
    </div>
  )
}

export default BlockEnd