import {useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
  const {t} = useTranslation();
  return (
    <footer className="w-auto bottom-0 h-52 md:h-48 sm:h-36 ml:h-32 mm:h-32 ms:h-32 flex gap-4 md:gap-2 justify-between items-center  p-[45px] sm:p-[20px] mm:p-[20px] ml:p-[20px] ms:p-[20px] bg-stone-900">
      <img src="/assets/logo.svg" width={100} height={70} alt='logo'/>
      <nav className="flex gap-8 md:gap-2 md:flex-wrap sm:flex-wrap  md:w-48 sm:hidden mm:hidden ml:hidden ms:hidden text-white">
        <Link to="/product" className="hover:text-[#FF4012]">{t('element.product')}</Link>
        <Link to="/quiz" className="hover:text-[#FF4012]">{t('element.quiz')}</Link>
        <Link to="/info" className="hover:text-[#FF4012]">{t('element.info')}</Link>
        <Link to="/about" className="hover:text-[#FF4012]">{t('element.about')}</Link>
      </nav>
      <div className="text-white font-bold text-2xl md:text-lg sm:text-lg mm:text-sm ms:text-sm ml:text-sm">
        <h3 className="mb-2">{t('element.text')}</h3>
        <a href="tel:+996986866">(0312) 98 68 66</a>
      </div>
      <Link to="/admin">
        <img src="/assets/logo-white.svg" width={104} height={104} alt='lang' className="ml:w-20 h-20 mm:hidden ms:hidden" />
      </Link>
    </footer>
  )
}

export default Footer