import Footer from "../components/Footer"
import Header from "../components/Header"
import ProductCom1 from "../components/product/ProductCom1"


const ProductPage = () => {
  return (
    <>
      <Header />
      <ProductCom1 />
      <Footer />
    </>
  )
}

export default ProductPage
