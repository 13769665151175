import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import MapCom from './MapCom';
import { TableRow } from '@mui/material';

const TableMap = () => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 1200 }} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell>
              <MapCom />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableMap;
