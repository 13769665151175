import {useTranslation} from 'react-i18next';
import Calculator from '../admin/Calculator'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { TableRow } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Pagination } from 'swiper/modules';

const ProductCom1 = () => {
  const {t} = useTranslation();
  const breakpoints = {
    1440: {
      slidesPerView: 1.7,
    },
    1280: {
      slidesPerView: 1.5,
    },
    1024: {
      slidesPerView: 1.18,
    },
    768: {
      slidesPerView: 0.85,
    },
    640: {
      slidesPerView: 0.7,
    },
    425: {
      slidesPerView: 0.45,
    },
    375: {
      slidesPerView: 0.7,
    },
    320: {
      slidesPerView: 0.8,
    },
  };
  return (
    <>
    <div className="xl:px-10 px-5 sm:p-2 py-3 flex flex-col gap-5 mb-28 sm:mb-16 ml:mb-10 mm:mb-8 ms:mb-5 mm:px-5 mm:py-5 ms:px-5 ms:py-5">
      <h1 className="font-bold text-2xl">Продукт</h1>
      <h2 className="font-bold text-xl">{t('product.desc')}</h2>
      <div className="flex justify-between md:flex-wrap md:gap-10 md:p-10 sm:flex-wrap sm:gap-4 ml:flex-col ml:gap-5 ml:justify-between ml:items-center mm:flex-col mm:gap-5 mm:justify-between mm:items-center ms:flex-col ms:gap-5 ms:justify-between ms:items-center">
        <div data-aos="fade-right" className="bg-[#222] hover:border-[#FF4012] hover:-translate-y-1 transition border p-6 xl:w-[370px] w-[300px]  h-[500px] text-white rounded-xl">
          <div className="flex p-4 h-[50%] items-center justify-center">
            <img src="/assets/about-c2.svg" width={204} height={253} className=""  alt="product card" />
          </div>
          <div className="flex h-[50%] flex-col justify-between">
            <div className="flex flex-col gap-2">
              <span className="bg-about w-[73px] p-2 rounded-xl font-semibold text-2xl">ЭЦП</span>
              <p className="text-xl font-semibold">1200сомов</p>
              <p className="text-sm">{t('product.t1')}</p>
            </div>
            <button className="px-9 w-[145px] hover:bg-gray-500 hover:-translate-y-1 transition py-3 bg-[#FF4012] rounded-xl text-white">
              <a target='_blank' href="https://enot.ubr.kg/statements">
                {t('product.t2')}
              </a>
            </button>
          </div>
        </div>
        <div data-aos="fade-right" className="bg-[#222] hover:border-[#FF4012] hover:-translate-y-1 transition border p-6 xl:w-[370px] w-[300px]  h-[500px] text-white rounded-xl">
          <div className="flex p-4 h-[50%] items-center justify-center">
            <img src="/assets/product-p1.svg" width={204} height={253} className=""  alt="product card" />
          </div>
          <div className="flex h-[50%] flex-col justify-between">
            <div className="flex flex-col gap-2">
              <span className="bg-about w-[110px] p-2 rounded-xl font-semibold text-2xl">Jacarta</span>
              <p className="text-xl font-semibold">3000сомов</p>
              <p className="text-sm">{t('product.t3')}</p>
            </div>
            <button className="px-9 w-[145px] hover:bg-gray-500 hover:-translate-y-1 transition py-3 bg-[#FF4012] rounded-xl text-white">
              <a target='_blank' href="https://enot.ubr.kg/statements">
                {t('product.t2')}
              </a>
            </button>
          </div>
        </div>
        <div data-aos="fade-right" className="bg-[#222] p-6 xl:w-[370px] hover:border-[#FF4012] hover:-translate-y-1 transition border w-[300px] sm:w-[300px] sm:translate-x-[60%] md:translate-x-[60%]  h-[500px] text-white rounded-xl">
          <div className="flex p-4 h-[50%] items-center justify-center">
            <img src="/assets/product-p2.svg" width={204} height={253} className=""  alt="product card" />
          </div>
          <div className="flex h-[50%] flex-col justify-between">
            <div className="flex flex-col gap-2">
              <span className="bg-about w-[185px] p-2 rounded-xl font-semibold text-2xl">Рутокен+ЭЦП</span>
              <p className="text-xl font-semibold">4200сомов</p>
              <p className="text-sm">{t('product.t4')}</p>
            </div>
            <button className="px-9 w-[145px] py-3 hover:bg-gray-500 hover:-translate-y-1 transition bg-[#FF4012] rounded-xl text-white">
              <a target='_blank' href="https://enot.ubr.kg/statements">
                {t('product.t2')}
              </a>
            </button>
            </div>
          </div>
        </div>
      </div>
      <div className="xl:px-10 px-5 py-3 mb-28 sm:mb-16 ml:mb-10 mm:mb-8 ms:mb-5 mm:px-5 mm:py-5 ms:px-5 ms:py-5">
        <Calculator />
      </div>
      <div className="mb-20">
        <Accordion className="bg-barbie">
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="xl:px-10 px-5 py-3 mb-10">
              <div className="flex gap-5 sm:flex-col ml:flex-col mm:flex-col ms:flex-col">
                <div className="flex flex-col items-start gap-3 w-[50%] justify-between sm:w-[100%] ml:w-[100%] mm:w-[100%] ms:w-[100%]">
                  <div className="lg:gap-2 flex justify-center items-center gap-4">
                    <span className="bg-black text-white rounded-full py-[5px] px-[14px] text-[18px] font-bold">
                      1
                    </span>
                    <p className="">{t('product.t5')}</p>
                  </div>
                  <div className="lg:gap-2 flex justify-center items-center gap-4">
                    <span className="bg-black text-white rounded-full py-[5px] px-[14px] text-[18px] font-bold">
                      2
                    </span>
                    <p className="">{t('product.t6')}</p>
                  </div>
                  <div className="lg:gap-2 flex justify-center items-center gap-4">
                    <span className="bg-black text-white rounded-full py-[5px] px-[14px] text-[18px] font-bold">
                      3
                    </span>
                    <p className="">{t('product.t7')} <a href="http://register.minjust.gov.kg/register/SearchAction.seam?logic=and&cid=204" className="font-bold underline text-[#FF4012]">{t('product.link')}</a>{t('product.link2')} </p>
                  </div>
                  <div className="lg:gap-2 flex justify-center items-center gap-4">
                    <span className="bg-black text-white rounded-full py-[5px] px-[14px] text-[18px] font-bold">
                      4
                    </span>
                    <p className="">{t('product.t8')}</p>
                  </div>
                  <div className="lg:gap-2 flex justify-center items-center gap-4">
                    <span className="bg-black text-white rounded-full py-[5px] px-[14px] text-[18px] font-bold">
                      5
                    </span>
                    <p className="">{t('product.t9')}</p>
                  </div>
                  <div className="lg:gap-2 flex justify-center items-center gap-4">
                    <span className="bg-black text-white rounded-full py-[5px] px-[14px] text-[18px] font-bold">
                      6
                    </span>
                    <p className="">{t('product.t10')} <a href="http://register.minjust.gov.kg/register/SearchAction.seam?logic=and&cid=204" className="font-bold underline text-[#FF4012]">{t('product.link3')}</a></p>
                  </div>
                </div>
                <div className="flex flex-col items-start gap-3 w-[50%] justify-between sm:w-[100%] ml:w-[100%] mm:w-[100%] ms:w-[100%]">
                  <div className="lg:gap-2 flex justify-center items-center gap-4">
                    <span className="bg-black text-white rounded-full py-[5px] px-[14px] text-[18px] font-bold">
                      7
                    </span>
                    <p className="">{t('product.t11')}</p>
                  </div>
                  <div className="lg:gap-2 flex justify-center items-center gap-4">
                    <span className="bg-black text-white rounded-full py-[5px] px-[14px] text-[18px] font-bold">
                      8
                    </span>
                    <p className="">{t('product.t12')} <a href="http://register.minjust.gov.kg/register/SearchAction.seam?logic=and&cid=204" className="font-bold underline text-[#FF4012]">{t('product.link3')}</a></p>
                  </div>
                  <div className="lg:gap-2 flex justify-center items-center gap-4">
                    <span className="bg-black text-white rounded-full py-[5px] px-[14px] text-[18px] font-bold">
                      9
                    </span>
                    <p className="">{t('product.t13')}</p>
                  </div>
                  <div className="lg:gap-2 flex justify-center items-center gap-4">
                    <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                      10
                    </span>
                    <p className="">{t('product.t14')}</p>
                  </div>
                  <div className="lg:gap-2 flex justify-center items-center gap-4">
                    <span className="bg-black text-white rounded-full py-[9px] px-[14px] text-[18px] font-bold">
                      11
                    </span>
                    <p className="">{t('product.t15')}</p>
                  </div>
                  <div className="lg:gap-2 flex justify-center items-center gap-4">
                    <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                      12
                    </span>
                    <p className="">{t('product.t16')}</p>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center mt-10">
                <button className=" px-9 w-[280px] py-3 hover:scale-105 transition bg-[#FF4012] rounded-xl text-white">
                {t('product.t17')}
                </button>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="xl:px-10 px-5 py-3 flex gap-5 sm:flex-col ml:flex-col mm:flex-col ms:flex-col">
              <div className="flex flex-col items-start gap-5 w-[50%] justify-between sm:w-[100%] ml:w-[100%] mm:w-[100%] ms:w-[100%]">
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    13
                  </span>
                  <p className="">{t('product.t18')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    14
                  </span>
                  <p className="">{t('product.t19')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    15
                  </span>
                  <p className="">{t('product.t20')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    16
                  </span>
                  <p className="">{t('product.t21')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    17
                  </span>
                  <p className="">{t('product.t22')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    18
                  </span>
                  <p className="">{t('product.t23')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    19
                  </span>
                  <p className="">{t('product.t24')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    20
                  </span>
                  <p className="">{t('product.t25')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    21
                  </span>
                  <p className="">{t('product.t26')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    22
                  </span>
                  <p className="">{t('product.t27')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    23
                  </span>
                  <p className="">{t('product.t28')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    24
                  </span>
                  <p className="">{t('product.t29')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    25
                  </span>
                  <p className="">{t('product.t30')}</p>
                </div>
              </div>
              <div className="flex flex-col items-start gap-5 w-[50%] justify-between sm:w-[100%] ml:w-[100%] mm:w-[100%] ms:w-[100%]">
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    26
                  </span>
                  <p className="">{t('product.t31')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    27
                  </span>
                  <p className="">{t('product.t32')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    28
                  </span>
                  <p className="">{t('product.t33')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    29
                  </span>
                  <p className="">{t('product.t34')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    30
                  </span>
                  <p className="">{t('product.t35')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    31
                  </span>
                  <p className="">{t('product.t36')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    32
                  </span>
                  <p className="">{t('product.t37')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    33
                  </span>
                  <p className="">{t('product.t38')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    34
                  </span>
                  <p className="">{t('product.t39')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    35
                  </span>
                  <p className="">{t('product.t40')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    36
                  </span>
                  <p className="">{t('product.t41')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    37
                  </span>
                  <p className="">{t('product.t42')}</p>
                </div>
                <div className="lg:gap-2 flex justify-center items-center gap-4">
                  <span className="bg-black text-white rounded-full py-[9px] px-[12px] text-[18px] font-bold">
                    38
                  </span>
                  <p className="">{t('product.t43')}</p>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="px-11 ms:px-3 mm:px-3 ml:px-3">
        <TableContainer>
          <Table sx={{ minWidth: 1200 }} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>
                  <>
                    <div className="flex items-center justify-center">
                      <div className="z-0 border-[2px]  bg-gradient-to-r from-[#FF4012]  to-[#FF4012] w-[213px] h-[213px] rounded-[100%] flex justify-center items-center ">
                        <div className="w-[204px] h-[205px]  bg-white rounded-[100%] flex justify-center items-center">
                          <span className="w-[150px] text-black text-[14px] font-bold">
                            {t('product.t44')}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center z-10 relative">
                        <div className="absolute -translate-x-2 bg-white shadow-md border rounded-[100%] w-3 h-3"></div>
                        <div className="h-[1px] w-[65px] bg-black"></div>
                        <div className="absolute translate-x-16 bg-white shadow-md border rounded-[100%] w-3 h-3"></div>
                      </div>
                      <div className="z-0 border-[2px]  bg-gradient-to-r from-[#FF4012]  to-[#FF4012] w-[374px] h-[374px] rounded-[100%] flex justify-center items-center ">
                        <div className="w-[366px] h-[366px]  bg-white rounded-[100%] flex justify-center items-center">
                          <span className="w-[265px] text-black text-[14px] font-bold">
                          {t('product.t45')}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center z-10 relative">
                        <div className="absolute -translate-x-2 bg-white shadow-md border rounded-[100%] w-3 h-3"></div>
                        <div className="h-[1px] w-[88px] bg-black"></div>
                        <div className="absolute translate-x-[84px] bg-white shadow-md border rounded-[100%] w-3 h-3"></div>
                      </div>
                      <div className="z-0 border-[2px]  bg-gradient-to-r from-[#FF4012]  to-[#FF4012] w-[437px] h-[437px] rounded-[100%] flex justify-center items-center ">
                        <div className="w-[427px] h-[427px]  bg-white rounded-[100%] flex justify-center items-center">
                          <span className="w-[324px] text-black text-[14px] font-bold">
                            {t('product.t46')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="mt-[100px]">
        <div className="relative flex items-center rounded-r-[99px] ms:w-[240px]  mm:w-[330px] ml:w-[330px] sm:w-[500px] md:w-[700px] w-[725px] h-[162px] bg-gradient-to-r from-[#FF4012] via-[#FE381C] to-[#F4015F]">
          <div className="md:pl-4 ms:pl-2 mm:pl-2 ml:pl-[10px] pl-[48px] flex gap-3 flex-col">
            <span className="ms:text-[14px] ms:pr-[30px] mm:text-[16px] mm:pr-[60px] ml:text-[20px] text-white text-[24px] font-bold">
              {t('product.t47')}
            </span>
            <span className="ms:text-[12px] ms:pr-[30px]  mm:text-[14px] mm:pr-[60px] text-white text-[16px] font-bold">
              {t('product.t48')}
            </span>
          </div>
          <div className="ms:translate-x-[135%] mm:translate-x-[170%] ml:translate-x-[270%] sm:translate-x-[350%] md:translate-x-[470%] absolute translate-x-[500%]">
            <img
              src="/assets/logo-white.svg"
              alt="enotface"
              className="ml:max-w-[100px] ml:max-h-[100px] min-w-[113px] min-h-[113px]"
            />
          </div>
        </div>
      </div>
      <div className="px-4">
        <>
          <div className="ms:flex-wrap mm:flex-wrap mm:gap-[10px] ml:flex-wrap ml:gap-[20px] sm:gap-[40px] md:gap-[80px] flex justify-evenly gap-[127px] mt-[34px]">
            <div>
              <div className="ms:w-[300px] mm:w-[300px] ml:w-[400px] sm:w-[270px] md:w-[300px] lg:w-[372px] xl:w-[372px] w-[587px]">
                <span className="text-black text-[16px]">
                  {t('product.t49')}
                </span>
              </div>
              <div className="flex flex-row ms:w-[300px] mm:w-[300px] ml:w-[400px] sm:w-[270px] md:w-[300px]  lg:w-[372px] xl:w-[372px] w-[616px] mt-[26px]">
                <div className="flex flex-col gap-[22px] mt-3">
                  <div className="flex flex-row gap-[10px] items-center">
                    <span
                      className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent shadow-2xl rounded-[100%] min-w-[12px] min-h-[12px]"
                      style={{
                        background:
                          'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                      }}
                    ></span>
                    <span>
                    {t('product.t50')}
                    </span>
                  </div>
                  <div className="flex flex-row gap-[10px] items-center ">
                    <span
                      className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%] min-w-[12px] min-h-[12px]"
                      style={{
                        background:
                          'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                      }}
                    ></span>
                    <span>
                    {t('product.t51')}
                    </span>
                  </div>
                  <div className="flex flex-row gap-[10px] items-center">
                    <span
                      className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%] min-w-[12px] min-h-[12px]"
                      style={{
                        background:
                          'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                      }}
                    ></span>
                    <span>
                      {t('product.t52')}
                    </span>
                  </div>
                  <div className="flex flex-row gap-[10px] items-center">
                    <span
                      className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%] min-w-[12px] min-h-[12px]"
                      style={{
                        background:
                          'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                      }}
                    ></span>
                    <span>{t('product.t53')}</span>
                  </div>
                  <div className="flex flex-row gap-[10px] items-center">
                    <span
                      className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%] min-w-[12px] min-h-[12px]"
                      style={{
                        background:
                          'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                      }}
                    ></span>
                    <span>{t('product.t54')}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-[58px] w-[472px]">
              <span className="text-[16px] text-black">
                {t('product.t55')}
              </span>
              <span className="text-[16px] text-black">
                {t('product.t56')}
              </span>
            </div>
          </div>
          {/* <div className="flex justify-center my-[34px]">
            <button>
              <a
                target="_blank"
                href="https://enot.ubr.kg/statements"
                className="bg-[#FF4012] px-[45px] py-[15px] text-[16px] text-white font-bold rounded-[10px]"
              >
                {t('product.t57')}
              </a>
            </button>
          </div> */}
        </>
      </div>
      <div className="ms:px-2 mm:px-2 px-11">
        <>
          <div className="flex justify-center mt-[80px]">
            <div className="ms:gap-0 ms:h-[160px] hover:border-[#FF4012] transition border ms:pt-0 mm:pt-0 mm:gap-0 mm:h-[150px] ml:pt-0 ml:gap-0 ml:h-[150px] sm:gap-2 md:gap-2 lg:w-[1000px] lg:gap-4 pt-[14px] rounded-[10px] bg-[#222222] w-[1190px] h-[97px] flex flex-col justify-center items-center gap-[14px]">
              <span className="ms:p-2 ms:text-[13px] mm:text-[13px] mm:p-2 ml:p-2 ml:text-[16px] sm:text-[10px] md:text-[13px] lg:text-[16px] font-bold text-white text-[20px]">
                {t('product.t58')}
              </span>
              <span className="ms:p-2 ms:text-[13px] mm:text-[12px] mm:p-2 ml:p-2 sm:text-[12px] md:text-[12px]  text-[16px] text-white">
                {t('product.t59')} 
                <a href="#" target="_blank" className="underline transition hover:text-[#FF4012]">
                  {t('product.t599')}
                </a>
              </span>
            </div>
          </div>
          <div className="mt-[34px]">
            <Swiper
              slidesPerView={1.8}
              spaceBetween={30}
              freeMode={true}
              modules={[FreeMode, Pagination]}
              className="w-full h-full"
              breakpoints={breakpoints}
              pagination={{
                dynamicBullets: true,
              }}
            >
              <SwiperSlide>
                <div className="ms:h-auto hover:border-[#FF4012] transition ms:max-w-[400px] mm:h-auto mm:max-w-[400px] py-[20px] pl-[20px] pr-[16px] border rounded-[10px] w-[781px] h-[196px]">
                  <span className="mm:text-[14px]">
                    {t('product.t60')}
                  </span>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="ms:h-auto hover:border-[#FF4012] transition ms:max-w-[400px] mm:h-auto mm:max-w-[400px] py-[20px] pl-[20px] pr-[16px] border rounded-[10px] w-[781px] h-[196px]">
                  <span className="mm:text-[14px]">
                  {t('product.t61')}
                  </span>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="ms:h-auto hover:border-[#FF4012] transition ms:max-w-[400px] mm:h-auto mm:max-w-[400px] py-[20px] pl-[20px] pr-[16px] border rounded-[10px] w-[781px] h-[196px]">
                  <span className="mm:text-[14px]">
                    {t('product.t62')}
                  </span>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="ms:h-auto hover:border-[#FF4012] transition ms:max-w-[400px] mm:h-auto mm:max-w-[400px] py-[20px] pl-[20px] pr-[16px] border rounded-[10px] w-[781px] h-[196px]">
                  <span className="mm:text-[14px]">
                  {t('product.t63')}
                  </span>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </>
      </div>
      <div className="ms:px-2 px-11">
        <>
          <div className="mt-[80px]">
            <h1 className="font-bold text-[20px] text-black">
            {t('product.t64')}
            </h1>
            <div className=" mt-[34px] flex-wrap flex gap-[39px]">
              <a href="/pdf/katalog_produktov.pdf" target="_blank">
                <div className="sm:w-[250px] hover:border-[#FF4012] transition border w-[299px] h-[66px] flex items-center justify-center gap-4 rounded-[10px] bg-[#222222]">
                  <span className="sm:text-[14px] text-[16px] text-white underline">
                    ООО “Код Безопасности”
                  </span>
                  <div className="p-3  px-4 bg-white rounded-[100%]">
                    <img src="/assets/product-arrow.svg" alt="arrow" />
                  </div>
                </div>
              </a>
              <a href="/pdf/katalog_produktov.pdf" target="_blank">
                <div className="sm:w-[250px] hover:border-[#FF4012] transition border w-[299px] h-[66px] flex items-center justify-center gap-4 rounded-[10px] bg-[#222222]">
                  <span className="sm:text-[14px] text-[16px] text-white underline">
                    ОАО Концерн “Автоматика”
                  </span>
                  <div className="p-3  px-4 bg-white rounded-[100%]">
                    <img src="/assets/product-arrow.svg" alt="arrow" />
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="sm:mb-[450px] mt-[17px] mb-[34px]">
            <img
              className="ms:h-[300px] w-[578px] h-[409px]"
              src="/assets/sertif.svg"
              alt="sertificate"
            />
          </div>
        </>
      </div>
      <div className="relative mt-[170px]">
        <img
          className="scale-x-[-1] ms:-translate-y-[90%] ms:translate-x-[0%] mm:-translate-y-[85%] ml:-translate-y-[90%] sm:-translate-y-[90%] md:-translate-y-[80%] absolute right-0 transform translate-x-[0%] -translate-y-[90%]"
          src="/assets/leftbottomvector.svg"
          alt="Вектор за формой снизу"
        />
        <div className="ms:p-2 lg:p-2 ms:w-[200px] ms:h-[200px] ms:-translate-y-[90%] mm:-translate-y-[100%] mm:w-[250px]  mm:h-[230px] ml:w-[250px] ml:h-[230px] ml:-translate-y-[100%] sm:-translate-y-[100%] md:-translate-y-[80%] absolute right-10 -translate-y-[95%] md:right-4 md:w-[280px] md:h-[270px] lg:right-4 lg:w-[300px] lg:h-[320px] w-[350px] h-[370px] rounded-[10px] bg-[#222222]">
          <div className="flex justify-center items-center flex-col">
            <div className="ms:gap-1 lg:p-0 ms:p-0 mm:p-[10px] ml:p-0 flex flex-col items-center p-[30px] gap-[12px]">
              <span className="md:text-[14px] ms:text-[14px] mm:text-[14px] ml:text-[16px] text-[20px] font-bold text-white text-center">
              {t('product.t65')}
              </span>
              <span className="lg:text-[18px] md:text-[14px] ms:justify-center ms:flex ms:text-[14px] mm:text-[16px] mm:flex mm:justify-center ml:text-[20px] ml:flex ml:justify-center text-[32px] text-white">
                <a href="tel:+996775985866">
                  0 775 98 58 66
                </a>
              </span>
              <span className="lg:text-[18px] md:text-[14px] ms:justify-center ms:flex ms:text-[14px] mm:text-[16px] mm:flex mm:justify-center ml:text-[20px] ml:flex ml:justify-center text-[32px] text-white">
              <a href="tel:+996501985866">
                0 501 98 58 66
              </a>
              </span>
              <span className="lg:text-[18px] md:text-[14px] ms:justify-center ms:flex ms:text-[14px] mm:text-[16px] mm:flex mm:justify-center ml:text-[20px] ml:flex ml:justify-center text-[32px] text-white">
                <a href="tel:+996772985866">
                  0 772 98 58 66
                </a>
              </span>
            </div>
            <div className="lg:mt-8 ms:w-[160px] ms:max-h-[40px] bg-[#FF4012] w-[185px] h-[49px] rounded-[10px] flex justify-center items-center ">
              <a
                href="https://enot.ubr.kg/statements"
                target="_blank"
                className="cursor-pointer text-[16px] text-white "
              >
                {t('product.t66')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductCom1