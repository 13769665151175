import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const QuizCom2 = () => {
  const { t } = useTranslation();
  return (
    <div className="flex sm:flex-col mm:flex-col ml:flex-col ms:flex-col justify-around text-center gap-10">
      <div>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px]"
          >
            <Typography className="text-white p-2"> {t('quiz.t')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t2')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t3')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t4')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t5')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t6')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t7')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              22122900 -{' '}
              <a
                target="_blank"
                href="http://cbd.minjust.gov.kg/act/view/ru-ru/300740"
                className="text-[#FF4012] underline"
              >
                {' '}
                {t('quiz.t8')}
              </a>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t9')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t10')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t11')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t12')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t13')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {t('quiz.t14')}{' '}
              <a
                href="http://cbd.minjust.gov.kg/act/view/ru-ru/111635"
                target="_blank"
                className="underline text-[#FF4012]"
              >
                {' '}
                {t('quiz.t144')}
              </a>{' '}
              {t('quiz.t1444')}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t15')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t16')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t17')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t18')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t19')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t20')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t21')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t22')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t23')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t24')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t25')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t26')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t27')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t28')}</Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <div>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t29')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t30')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t31')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t32')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t33')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t34')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t35')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t36')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t37')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {t('quiz.t38')}{' '}
              <a
                href="http://cbd.minjust.gov.kg/act/view/ru-ru/111635"
                className="text-[#FF4012] underline"
                target="_blank"
              >
                {' '}
                {t('quiz.t388')}
              </a>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t39')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t40')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2">
              {' '}
              {t('quiz.t401')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {t('quiz.t41')}
              <a
                href="http://cbd.minjust.gov.kg/act/view/ru-ru/111635"
                className="text-[#FF4012] underline"
                target="_blank"
              >
                {' '}
                {t('quiz.t411')}
              </a>{' '}
              {t('quiz.t4')}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t42')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t43')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t44')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t45')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t46')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {t('quiz.t47')}
              <Link to="/info" className="text-[#FF4012] underline">
                {' '}
                {t('quiz.pereti')}
              </Link>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t48')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {t('quiz.t49')}
              <Link to="/about" className="text-[#FF4012] underline">
                {' '}
                {t('quiz.pereti')}
              </Link>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t50')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t51')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] mb-10 rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t52')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('quiz.t53')}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="max-w-[578px] rounded-3xl">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="bg-acordion-bg rounded-3xl min-h-[88px] md:min-h-[136px] "
          >
            <Typography className="text-white p-2"> {t('quiz.t54')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {t('quiz.t55')}
              <a
                href="http://cbd.minjust.gov.kg/act/view/ru-ru/111635"
                target="_blank"
                className="text-[#FF4012] underline"
              >
                {' '}
                {t('quiz.t555')}
              </a>{' '}
              {t('quiz.t5555')}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default QuizCom2;
