import React, { useState, useRef } from 'react';
import { db } from '../../firebaseConfig';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

const Calculator = () => {
  const {t} = useTranslation();
  const [open, setOpen] = useState(true);
  const [isCaptchaVisible, setIsCaptchaVisible] = useState(false);
  const [isCaptchaPassed, setIsCaptchaPassed] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const recaptchaRef = useRef();

  const handleFormSubmitAttempt = () => {
    if (!isCaptchaVisible) {
      // Если капча не видима, то показываем её
      setIsCaptchaVisible(true);
    }
  };

  const handleClose = (reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };
  // Значение стоимости и Вида
  const [formData, setFormData] = useState({
    ecp: { quantity: 0, deadline: '' },
    jacarta: { quantity: 0, deadline: '' },
    kkm: { quantity: 0, deadline: '' },
    rutoken: { quantity: 0, deadline: '' },
    reports: { quantity: 0, deadline: '' },
    osago: { quantity: 0, deadline: '' },
  });

  const [fio, setFio] = useState('');
  const [phone, setPhone] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const handleSubmit = async () => {
    try {
      if (isCaptchaPassed) {
        const currentDate = new Date();
        await db.collection('formData').add({
          ecp: formData.ecp,
          jacarta: formData.jacarta,
          kkm: formData.kkm,
          rutoken: formData.rutoken,
          reports: formData.reports,
          osago: formData.osago,
          fio,
          phone,
          submittedAt: currentDate,
        });

        setModalVisible(false);
        setFormData('');
        setIsCaptchaPassed(false);
        setIsFormSubmitted(true);

        recaptchaRef.current.reset();
      } else {
        console.log('Капча не пройдена');
      }
    } catch (error) {
      console.error('Submit error:', error);
    }
  };

  const [activeButton, setActiveButton] = useState('button1');
  const [content, setContent] = useState(
    <div className="flex flex-row">
      <div className=" flex flex-col gap-3 mt-3">
        <div className="flex flex-row gap-[10px] items-center">
          <span
            className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent shadow-2xl rounded-[100%] min-w-[12px] min-h-[12px]"
            style={{
              background:
                'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
            }}
          ></span>
          <span>{t('calculator.t1')}</span>
        </div>
        <div className="flex flex-row gap-[10px] items-center ">
          <span
            className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%] min-w-[12px] min-h-[12px]"
            style={{
              background:
                'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
            }}
          ></span>
          <span>{t('calculator.t2')}</span>
        </div>
        <div className="flex flex-row gap-[10px] items-center">
          <span
            className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%] min-w-[12px] min-h-[12px]"
            style={{
              background:
                'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
            }}
          ></span>
          <span>Решение/ протокол о назначении на должность руководителя</span>
        </div>
        <div className="flex flex-row gap-[10px] items-center">
          <span
            className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%] min-w-[12px] min-h-[12px]"
            style={{
              background:
                'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
            }}
          ></span>
          <span>
          {t('calculator.t4')}
          </span>
        </div>
        <div className="flex flex-row gap-[10px] items-center">
          <span
            className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%] min-w-[12px] min-h-[12px]"
            style={{
              background:
                'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
            }}
          ></span>
          <span>{t('calculator.t5')} 170$</span>
        </div>
      </div>
    </div>
  );

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);

    switch (buttonId) {
      case 'button1':
        setContent(
          <div className="flex flex-row">
            <div className="flex flex-col gap-3 mt-3">
              <div className="flex flex-row gap-[10px] items-center">
                <span
                  className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent shadow-2xl rounded-[100%]  w-3 h-3"
                  style={{
                    background:
                      'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                  }}
                ></span>
                <span>{t('calculator.t6')}</span>
              </div>
              <div className="flex flex-row gap-[10px] items-center ">
                <span
                  className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%]  w-3 h-3"
                  style={{
                    background:
                      'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                  }}
                ></span>
                <span>{t('calculator.t8')}</span>
              </div>
              <div className="flex flex-row gap-[10px] items-center">
                <span
                  className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%]  w-3 h-3"
                  style={{
                    background:
                      'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                  }}
                ></span>
                <span>
                  {t('calculator.t9')}
                </span>
              </div>
              <div className="flex flex-row gap-[10px] items-center">
                <span
                  className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%]  w-3 h-3"
                  style={{
                    background:
                      'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                  }}
                ></span>
                <span>
                  {t('calculator.t10')}
                </span>
              </div>
              <div className="flex flex-row gap-[10px] items-center">
                <span
                  className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%]  w-3 h-3"
                  style={{
                    background:
                      'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                  }}
                ></span>
                <span>{t('calculator.t5')}170$</span>
              </div>
            </div>
          </div>
        );
        break;
      case 'button2':
        setContent(
          <>
            <div className="flex flex-row">
              <div className="flex flex-col gap-3 mt-3">
                <div className="flex flex-row gap-[10px] items-center">
                  <span
                    className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent shadow-2xl rounded-[100%]  min-w-[12px] min-h-[12px]"
                    style={{
                      background:
                        'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                    }}
                  ></span>
                  <span>{t('calculator.t11')}</span>
                </div>
                <div className="flex flex-row gap-[10px] items-center ">
                  <span
                    className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%]  min-w-[12px] min-h-[12px]"
                    style={{
                      background:
                        'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                    }}
                  ></span>
                  <span>
                    {t('calculator.t12')}
                  </span>
                </div>
                <div className="flex flex-row gap-[10px] items-center">
                  <span
                    className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%]  min-w-[12px] min-h-[12px]"
                    style={{
                      background:
                        'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                    }}
                  ></span>
                  <span>
                    {t('calculator.t13')}
                  </span>
                </div>
                <div className="flex flex-row gap-[10px] items-center">
                  <span
                    className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%]  min-w-[12px] min-h-[12px]"
                    style={{
                      background:
                        'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                    }}
                  ></span>
                  <span>{t('calculator.t14')}</span>
                </div>
                <div className="flex flex-row gap-[10px] items-center">
                  <span
                    className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%]  min-w-[12px] min-h-[12px]"
                    style={{
                      background:
                        'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                    }}
                  ></span>
                  <span>
                    {t('calculator.t15')}
                  </span>
                </div>
                <div className="flex flex-row gap-[10px] items-center">
                  <span
                    className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%]  min-w-[12px] min-h-[12px]"
                    style={{
                      background:
                        'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                    }}
                  ></span>
                  <span>
                    {t('calculator.t16')}
                  </span>
                </div>
                <div className="flex flex-row gap-[10px] items-center">
                  <span
                    className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%]  min-w-[12px] min-h-[12px]"
                    style={{
                      background:
                        'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                    }}
                  ></span>
                  <span>
                    {t('calculator.t17')}
                  </span>
                </div>
              </div>
            </div>
            <div className="my-[25px]">
              <span className=" text-red-500 font-bold">
                {t('calculator.t18')}
              </span>
            </div>
            <div>
              {t('calculator.t19')}
              <div className="flex flex-row">
                <div className="flex flex-col gap-3 my-4">
                  <div className="flex flex-row gap-[10px] items-center">
                    <span
                      className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent shadow-2xl rounded-[100%] min-w-[12px] min-h-[12px]"
                      style={{
                        background:
                          'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                      }}
                    ></span>
                    <span>
                      {t('calculator.t20')}
                    </span>
                  </div>
                  <div className="flex flex-row gap-[10px] items-center ">
                    <span
                      className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%] min-w-[12px] min-h-[12px]"
                      style={{
                        background:
                          'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                      }}
                    ></span>
                    <span>
                      {t('calculator.t21')}
                    </span>
                  </div>
                  <div className="flex flex-row gap-[10px] items-center">
                    <span
                      className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%]  min-w-[12px] min-h-[12px]"
                      style={{
                        background:
                          'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                      }}
                    ></span>
                    <span>
                      {t('calculator.t22')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
        break;
      case 'button3':
        setContent(
          <div className="flex flex-row">
            <div className="flex flex-col gap-3 mt-3">
              <div className="flex flex-row gap-[10px] items-center">
                <span
                  className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent shadow-2xl rounded-[100%] min-w-[12px] min-h-[12px]"
                  style={{
                    background:
                      'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                  }}
                ></span>
                <span>{t('calculator.t23')}</span>
              </div>
              <div className="flex flex-row gap-[10px] items-center ">
                <span
                  className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%] min-w-[12px] min-h-[12px]"
                  style={{
                    background:
                      'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                  }}
                ></span>
                <span>
                  {t('calculator.t24')}
                </span>
              </div>
              <div className="flex flex-row gap-[10px] items-center">
                <span
                  className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%] min-w-[12px] min-h-[12px]"
                  style={{
                    background:
                      'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                  }}
                ></span>
                <span>{t('calculator.t25')}</span>
              </div>
              <div className="flex flex-row gap-[10px] items-center">
                <span
                  className="bg-gradient-to-tr from-red-600 via-pink-600 to-transparent rounded-[100%] min-w-[12px] min-h-[12px]"
                  style={{
                    background:
                      'linear-gradient(4deg, #FF4012 -25.68%, #F4015F 159.24%)',
                  }}
                ></span>
                <span>{t('calculator.t26')}</span>
              </div>
            </div>
          </div>
        );
        break;
      default:
        setContent(null);
    }
  };

  return (
    <>
      <div>
        <div>
          <h1 className="text-[24px] font-bold">
            {t('calculator.t27')}
          </h1>
          <div className="ms:flex-col ms:items-start ms:gap-[20px] mm:flex-col mm:items-start mm:gap-[20px] ml:flex-col ml:items-start ml:gap-[20px] sm:flex-col sm:items-start sm:gap-[25px] md:flex-wrap md:gap-[64px] flex justify-between py-[30px]">
            <div className="lg:gap-2 flex justify-center items-center gap-4">
              <span className="bg-black text-white rounded-full p-2 px-[18px] text-[20px] font-bold">
                1
              </span>
              <p className="md:w-[230px] lg:w-[100px]">{t('calculator.t28')}</p>
            </div>
            <div className="lg:gap-2 flex justify-center items-center gap-4">
              <span className="bg-black text-white rounded-full p-2 px-[17px] text-[20px] font-bold">
                2
              </span>
              <p className="md:w-[230px] lg:w-[170px] w-[200px]">
                {t('calculator.t29')}
              </p>
            </div>
            <div className="lg:gap-2 flex justify-center items-center gap-4">
              <span className="bg-black text-white rounded-full p-2 px-[16px] text-[20px] font-bold">
                3
              </span>
              <p className="md:w-[230px] w-[230px]">
                {t('calculator.t30')}
              </p>
            </div>
            <div className="lg:gap-2 flex justify-center items-center gap-4">
              <span className="bg-black text-white rounded-full p-2 px-[16px] text-[20px] font-bold">
                4
              </span>
              <p className="md:w-[230px] lg:w-[190px] w-[230px]">
                {t('calculator.t31')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className="ms:flex-wrap ms:w-[270px] mm:flex-wrap mm:w-[300px] ml:flex-wrap ml:w-[300px] sm:flex-wrap sm:w-[400px] flex w-[720px] gap-3">
            <button
              onClick={() => handleButtonClick('button1')}
              className={`px-[2px] w-[179px] h-[39px] rounded-[14px] focus:outline-none ${
                activeButton === 'button1'
                  ? ' text-white  rounded-[14px]  bg-gradient-to-r from-red-500  to-orange-500'
                  : ' text-gray-600 bg-gray-400'
              }`}
            >
              <p className="flex py-[5px]  rounded-[12px] items-center justify-center bg-white back text-black text-[16px] ">
              {t('calculator.t32')}
              </p>
            </button>
            <button
              onClick={() => handleButtonClick('button2')}
              className={`px-[2.5px] w-[180px] h-[39px] rounded-[14px] focus:outline-none ${
                activeButton === 'button2'
                  ? ' text-white  rounded-[14px]  bg-gradient-to-r from-red-500  to-orange-500'
                  : ' text-gray-600 bg-gray-400'
              }`}
            >
              <p className="flex py-[5px] rounded-[12px] items-center justify-center bg-white back text-black text-[16px] ">
                {t('calculator.t33')}
              </p>
            </button>
            <button
              onClick={() => handleButtonClick('button3')}
              className={`ms:h-[59px] px-[2.5px] w-[329px] h-[39px] rounded-[14px] focus:outline-none ${
                activeButton === 'button3'
                  ? ' text-white  rounded-[14px]  bg-gradient-to-r from-red-500  to-orange-500'
                  : ' text-gray-600 bg-gray-400'
              }`}
            >
              <p className="ms:h-[55px] flex py-[5px] rounded-[12px] items-center justify-center bg-white back text-black text-[16px] ">
                {t('calculator.t34')}
              </p>
            </button>
          </div>
          <div className="ms:flex-col ms:items-start mm:flex-col mm:items-center ml:flex-col ml:items-center sm:flex-col sm:items-center md:flex-col md:items-center flex justify-between">
            <div data-aos="fade-right" className="ms:w-[300px] px-5 ms:pb-5 mm:w-[350px] mm:pb-5 ml:w-[350px] ml:pb-5 sm:pb-5 sm:w-[552px] md:w-[719px] md:min-h-[400px] xl:w-[700px] lg:w-[500px] text-block mt-[45px] border rounded-xl shadow-2xl pt-5 w-[780px]">
              <span>
                {t('calculator.t35')}
              </span>
              <div>{content}</div>
            </div>
            <div data-aos="fade-right" className="ms:w-[300px] mm:w-[350px] ml:w-[350px] lg:w-[400px] lg:h-[440px] lg:gap-4 mt-[45px] h-[500px] w-[500px] p-[20px] ms:p-[5px] mm:p-[10px] border flex flex-col gap-7 shadow-2xl rounded-xl">
              <div className="flex items-center justify-between">
                <h2 className="w-[10%]">ЭЦП</h2>
                <FormControl size="small">
                  <InputLabel id="sel1">Цена</InputLabel>
                  <Select
                    id="sel1"
                    className="ms:w-[100px] w-32 h-13 rounded-xl"
                    label="ЭЦП"
                    onChange={(event) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        ecp: { ...prevData.ecp, quantity: event.target.value },
                      }))
                    }
                  >
                    <MenuItem value={1200}>1200</MenuItem>
                  </Select>
                </FormControl>
                <FormControl size="small">
                  <InputLabel id="sel2">Вид</InputLabel>
                  <Select
                    id="sel2"
                    label="ЭЦП"
                    className="ms:w-[100px] w-24 h-13 rounded-xl"
                    onChange={(event) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        ecp: { ...prevData.ecp, deadline: event.target.value.toString() },
                      }))
                    }
                  >
                    <MenuItem value={"Год"}>Год</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="flex items-center justify-between">
                <h2 className="w-[10%]">Jacarta</h2>
                <FormControl size="small">
                  <InputLabel id="sel3">Цена</InputLabel>
                  <Select
                    id="sel3"
                    label="jacarta"
                    className="ms:w-[100px] w-32 h-13 rounded-xl"
                    onChange={(event) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        jacarta: {
                          ...prevData.jacarta,
                          quantity: event.target.value,
                        },
                      }))
                    }
                  >
                    <MenuItem value={3000}>3000</MenuItem>
                  </Select>
                </FormControl>
                <FormControl size="small">
                  <InputLabel id="sel4">Вид</InputLabel>
                  <Select
                    id="sel4"
                    label="jacarta"
                    className="ms:w-[100px] w-24 h-13 rounded-xl"
                    onChange={(event) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        jacarta: {
                          ...prevData.jacarta,
                          deadline: event.target.value.toString(),
                        },
                      }))
                    }
                  >
                    <MenuItem value={"Товар"}>Товар</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="flex items-center justify-between">
                <h2 className="w-[10%]">ККМ</h2>
                <FormControl size="small">
                  <InputLabel id="sel5">Цена</InputLabel>
                  <Select
                    id="sel5"
                    label="kkm"
                    className="ms:w-[100px] w-32 h-13 rounded-xl"
                    onChange={(event) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        kkm: { ...prevData.kkm, quantity: event.target.value },
                      }))
                    }
                  >
                    <MenuItem value={14500}>14500</MenuItem>
                  </Select>
                </FormControl>
                <FormControl size="small">
                  <InputLabel id="sel6">Вид</InputLabel>
                  <Select
                    id="sel6"
                    label="kkm"
                    className="ms:w-[100px] w-24 h-13 rounded-xl"
                    onChange={(event) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        kkm: { ...prevData.kkm, deadline: event.target.value.toString() },
                      }))
                    }
                  >
                    <MenuItem value={"Товар"}>Товар</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="flex items-center justify-between">
                <h2 className="w-[10%]">Rutoken</h2>
                <FormControl size="small">
                  <InputLabel id="sel7">Цена</InputLabel>
                  <Select
                    id="sel7"
                    className="ms:w-[100px] w-32 h-13 rounded-xl"
                    label="rutoken"
                    onChange={(event) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        rutoken: {
                          ...prevData.rutoken,
                          quantity: event.target.value,
                        },
                      }))
                    }
                  >
                    <MenuItem value={3000}>3000</MenuItem>
                  </Select>
                </FormControl>
                <FormControl size="small">
                  <InputLabel id="sel8">Вид</InputLabel>
                  <Select
                    id="sel8"
                    label="rutoken"
                    className="ms:w-[100px] w-24 h-13 rounded-xl"
                    onChange={(event) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        rutoken: {
                          ...prevData.rutoken,
                          deadline: event.target.value.toString(),
                        },
                      }))
                    }
                  >
                    <MenuItem value={"Товар"}>Товар</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="flex items-center justify-between">
                <h2 className="w-[10%]">Reports</h2>
                <FormControl size="small">
                  <InputLabel id="sel9">Цена</InputLabel>
                  <Select
                    id="sel9"
                    label="reports"
                    className="ms:w-[100px] w-32 h-13 rounded-xl"
                    onChange={(event) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        reports: {
                          ...prevData.reports,
                          quantity: event.target.value,
                        },
                      }))
                    }
                  >
                    <MenuItem value={2000}>2000</MenuItem>
                  </Select>
                </FormControl>
                <FormControl size="small">
                  <InputLabel id="sel10">Вид</InputLabel>
                  <Select
                    id="sel10"
                    label="reports"
                    className="ms:w-[100px] w-24 h-13 rounded-xl"
                    onChange={(event) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        reports: {
                          ...prevData.reports,
                          deadline: event.target.value.toString(),
                        },
                      }))
                    }
                  >
                    <MenuItem value={"Товар"}>Товар</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="flex items-center justify-between">
                <h2 className="w-[10%]">Osago</h2>
                <FormControl size="small">
                  <InputLabel id="sel11">Цена</InputLabel>
                  {/* <Select
                    id="sel11"
                    label="osago"
                    className="ms:w-[100px] w-32 h-13 rounded-xl"
                    onChange={(event) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        osago: {
                          ...prevData.osago,
                          quantity: event.target.value,
                        },
                      }))
                    }
                  >
                    <MenuItem value="2000">2000</MenuItem>
                  </Select> */}
                </FormControl>
                <FormControl size="small">
                  <InputLabel id="sel12">Вид</InputLabel>
                  <Select
                    id="sel12"
                    label="osago"
                    className="ms:w-[100px] w-24 h-13 rounded-xl"
                    onChange={(event) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        osago: {
                          ...prevData.osago,
                          deadline: event.target.value.toString(),
                        },
                      }))
                    }
                  >
                    <MenuItem value="Год">Год</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <button
                onClick={() => setModalVisible(true)}
                className="items-center rounded-md justify-center bg-[#FF4012] px-3 py-[15px] text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto"
              >
                {t('calculator.t36')}
              </button>
              {modalVisible && (
                <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                  <DialogTitle className="flex justify-between font-bold items-center text-2xl">
                  {t('calculator.t37')}
                    <Button onClick={handleClose}>
                      <HighlightOffOutlinedIcon className="text-[#000] text-4xl" />
                    </Button>
                  </DialogTitle>
                  <DialogContent>
                    <Box component="form">
                      <div className="flex gap-3">
                        <input
                          className="rounded-md border p-2"
                          type="text"
                          placeholder="Ф.И.О."
                          value={fio}
                          onChange={(event) => setFio(event.target.value)}
                        />
                        <input
                          className="rounded-md border p-2"
                          type="tel"
                          placeholder="Телефон"
                          value={phone}
                          onChange={(event) => setPhone(event.target.value)}
                        />
                      </div>
                    </Box>
                  </DialogContent>
                  {isCaptchaVisible && (
                    <ReCAPTCHA
                      sitekey="6LeYBfEnAAAAAJ-OfbIQ4VaN9mjsjwyFtrilVj6p"
                      ref={recaptchaRef}
                      onChange={(value) => setIsCaptchaPassed(!!value)}
                      className="pl-6"
                    />
                  )}
                  <DialogActions>
                    <Button
                      type="button"
                      className={`items-center rounded-md justify-center bg-[#FF4012] px-[50%] py-[10px] text-white shadow-sm hover:bg-red-50 ${
                        isFormSubmitted ? 'cursor-not-allowed opacity-50' : ''
                      }`}
                      onClick={
                        isFormSubmitted
                          ? null
                          : isCaptchaVisible
                          ? handleSubmit
                          : handleFormSubmitAttempt
                      }
                      disabled={isFormSubmitted}
                    >
                      {isFormSubmitted ? 'Отправлено' : 'Отправить'}
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calculator;
