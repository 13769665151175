import Header from "../components/Header"
import AdminCom from "../components/admin/AdminCom"


const AdminPage = () => {
  return (
    <>
      <Header />
      <AdminCom />
    </>
  )
}

export default AdminPage