import { useTranslation } from 'react-i18next';

const Univers = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1 className="font-bold text-[24px]">{t('main.t21')}</h1>
      <div className="mt-[38px] relative flex sm:justify-center">
        <div className="ms:w-[300px] mm:w-[300px] sm:flex-col  md:max-w-[400px] lg:max-w-[850px] flex flex-wrap max-w-[1000px] justify-center gap-[38px]">
          <div className="ms:visible mm:visible sm:visible md:hidden  lg:hidden xl:hidden ">
            <img
              src="/assets/fleshka.png"
              alt="fleshka"
              className="ms:w-[250px] ms:h-[250px] mm:w-[250px] mm:h-[250px] ml:w-[250px] ml:h-[250px] md:w-[300px] md:h-[300px] lg:w-[300px] lg:h-[300px] w-[391px] h-[391px] "
            />
          </div>
          <div className="ms:p-[18px] hover:border-[#FF4012] hover:scale-105 transition border ms:w-[300px] mm:p-[18px] mm:w-[300px] ml:w-[350px] lg:w-[350px] relative p-[38px] border rounded-[10px] w-[408px] h-[226px] shadow-sm flex justify-center items-center">
            <div className="ms:-translate-x-[160%] ms:-translate-y-[180%]  mm:-translate-x-[160%] mm:-translate-y-[180%] ml:-translate-x-[150%] lg:-translate-x-[170%] lg:-translate-y-[150%] absolute transform -translate-x-[200%] -translate-y-[140%]">
              <img
                src="/assets/unvtriugol.svg"
                alt="unvtriugol"
                className="mm:w-[100px] mm:h-[60px] lg:max-w-[80px] lg:max-h-[50px] w-[112px] h-[74px]"
              />
            </div>
            <span className="lg:text-[16px] text-[18px] ">{t('main.t22')}</span>
          </div>
          <div className="ms:w-[300px] hover:border-[#FF4012] hover:scale-105 transition ml:w-[350px] lg:w-[350px] relative border rounded-[10px] w-[408px] h-[226px] shadow-sm flex justify-center items-center flex-col">
            <div className="lg:-translate-x-[300%] lg:-translate-y-[240%] absolute transform -translate-x-[350%] -translate-y-[220%]">
              <img
                src="/assets/unvtriugoltwo.svg"
                alt="unvtriugol2"
                className="min-w-[56px] min-h-[37px]"
              />
            </div>
            <div className="ms:p-[18px] ">
              <span className="lg:text-[16px] text-[18px] ">
                {t('main.t23')}
              </span>
              <div className="flex flex-row p-2 gap-1">
                <div className="flex flex-col gap-4 pt-[5px]">
                  <span className="min-w-[9px] min-h-[9px] bg-black rounded-[100%]"></span>
                  <span className="min-w-[9px] min-h-[9px] bg-black rounded-[100%]"></span>
                  <span className="min-w-[9px] min-h-[9px] bg-black rounded-[100%]"></span>
                </div>
                <div className="flex flex-col">
                  <span>{t('main.t24')}</span>
                  <span>{t('main.t25')}</span>
                  <span>{t('main.t26')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="ml:w-[350px] md:hidden hover:border-[#FF4012] hover:scale-105 transition  lg:w-[350px] relative border rounded-[10px] w-[408px] h-[226px] shadow-sm flex justify-center items-center">
            <div className="ms:translate-x-[230%] absolute transform translate-x-[250%] translate-y-[100%]">
              <img
                src="/assets/unvtriugoltri.svg"
                alt="unvtriugol2"
                className="min-w-[56px] min-h-[37px]"
              />
            </div>
            <span className="lg:text-[16px] text-[18px] p-[40px]">
              {t('main.t27')}
            </span>
          </div>
        </div>
        <div className="ms:hidden mm:hidden ml:hidden sm:hidden md:translate-x-[120%] md:translate-y-[30%]  lg:translate-x-[200%] lg:translate-y-[50%] absolute transform translate-x-[225%]">
          <img
            src="/assets/fleshka.png"
            alt="fleshka"
            className="md:w-[300px] md:h-[300px] lg:w-[300px] lg:h-[300px] w-[391px] h-[391px] "
          />
        </div>
      </div>
      <div className="md:visible sm:hidden ms:hidden lg:hidden xl:hidden mm:hidden ml:hidden md:ml-[250px] md:mt-5 lg:w-[350px] relative border rounded-[10px] w-[408px] h-[226px] shadow-sm flex justify-center items-center">
        <div className="absolute transform translate-x-[250%] translate-y-[100%]">
          <img
            src="/assets/unvtriugoltri.svg"
            alt="unvtriugol2"
            className="min-w-[56px] min-h-[37px]"
          />
        </div>
        <span className="lg:text-[16px] text-[18px] p-[40px]">
          {t('main.t27')}
        </span>
      </div>
    </div>
  );
};

export default Univers;
