import { useTranslation } from 'react-i18next';

const ServicesCom = () => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <h1 className="pl-2 text-[24px] font-bold">{t('main.t13')}</h1>
        <div className="ms:justify-center ms:flex-wrap ms:gap-[14px]  mm:justify-center mm:flex-wrap mm:gap-[14px] ml:justify-center ml:flex-wrap ml:gap-[14px] sm:justify-center sm:flex-wrap sm:gap-[14px] md:flex-wrap md:justify-center md:gap-2 mt-[34px] flex flex-row  justify-between">
          <div
            data-aos="fade-right"
            className="hover:border-[#FF4012] hover:scale-105 border transition ms:w-[300px] mm:w-[300px] ml:w-[300px] lg:w-[300px] bg-[#222222] w-[374px] h-auto shadow-sm rounded-[10px]"
          >
            <img
              src="/assets/cap.svg"
              alt="cap"
              className="ms:mx-[50px] ml:mx-[50px] lg:mx-[50px] max-w-[200px] max-h-[231px] mx-[87px] mt-[22px]"
            />
            <div className="flex flex-col gap-2 justify-start m-[15px]">
              <a className="lg:text-[20px] cursor-pointer flex justify-center items-center font-bold text-[24px] text-white w-[134px] h-[50px] rounded-[10px] bg-gradient-to-r from-[#FC3100]  to-[#F4015F]">
                КЭП
              </a>
              <h1 className=" text-white font-bold text-[20px]">{t('main.t14')}</h1>
              <p className="text-white font-normal text-[16px] h-auto">
                {t('main.t15')}
              </p>
            </div>
          </div>
          <div
            data-aos="fade-right"
            className="ms:w-[300px] hover:border-[#FF4012] hover:scale-105 border transition  mm:w-[300px] ml:w-[300px] lg:w-[300px] bg-[#222222] w-[374px] h-auto shadow-sm rounded-[10px]"
          >
            <img
              src="/assets/shit.svg"
              alt="cap"
              className="ms:mx-[50px] ml:mx-[50px] lg:mx-[50px] max-w-[200px] max-h-[231px] mx-[87px] mt-[22px]"
            />
            <div className="lg:mt-[19%] flex flex-col gap-2 justify-start mt-[15%] m-[15px]">
              <a className="ms:w-[260px] ms:text-[20px] mm:w-[240px] mm:text-[20px] ml:w-[240px] ml:text-[20px] lg:w-[240px] lg:text-[20px] cursor-pointer flex justify-center items-center font-bold text-[24px] text-white w-[310px] h-[50px] rounded-[10px] bg-gradient-to-r from-[#FC3100]  to-[#F4015F]">
                {t('main.t16')}
              </a>
              <h1 className=" text-white font-bold text-[20px]">{t('main.t17')}</h1>
              <p className="text-white font-normal text-[16px] h-auto">
                {t('main.t18')}
              </p>
            </div>
          </div>
          <div
            data-aos="fade-right"
            className="ms:w-[300px] hover:border-[#FF4012] hover:scale-105 border transition mm:w-[300px] ml:w-[300px] lg:w-[300px] bg-[#222222] w-[374px] h-auto shadow-sm rounded-[10px]"
          >
            <img
              src="/assets/teh.svg"
              alt="cap"
              className="ms:mx-[60px] ml:mx-[60px] lg:mx-[60px] max-w-[200px] max-h-[231px] mx-[87px] mt-[22px]"
            />
            <div className="flex flex-col gap-2 justify-start m-[15px]">
              <a className="lg:text-[20px] cursor-pointer flex justify-center items-center font-bold text-[24px] text-white w-[202px] h-[50px] rounded-[10px] bg-gradient-to-r from-[#FC3100]  to-[#F4015F]">
                {t('main.t19')}
              </a>
              <h1 className="text-white font-bold text-[20px]">
                <a href="+996775985866">+996 775 98 58 66</a>
              </h1>
              <h1 className="text-white font-bold text-[20px]">
                <a href="+996501985866">+996 501 98 58 66</a>
              </h1>
              <h1 className="text-white font-bold text-[20px]">
                <a href="+996772985866">+996 772 98 58 66</a>
              </h1>
              <p className="text-white font-normal text-[16px] h-auto">
                {t('main.t20')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesCom;
