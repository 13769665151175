import Footer from "../components/Footer"
import Header from "../components/Header"
import InfoCom1 from "../components/info/InfoCom1"

const InfoPage = () => {
  return (
    <>
      <Header />
      <InfoCom1 />
      <Footer />
    </>
  )
}

export default InfoPage