import React from 'react';
import ReactDOM from 'react-dom/client';
import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage';
import QuizPage from './pages/QuizPage';
import InfoPage from './pages/InfoPage';
import AboutPage from './pages/AboutPage';
import AdminPage from './pages/AdminPage';
import LoginPage from './pages/LoginPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "./i18n";
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter basename="/">    
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/quiz" element={<QuizPage />} />
        <Route path="/info" element={<InfoPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
);
