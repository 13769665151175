import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from "@mui/icons-material/Telegram";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import { useTranslation } from "react-i18next";
import ModalContact from "../admin/ModalContact";

const AboutCom1 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="realitive z-0 flex ml:flex-col mm:flex-col ms:flex-col w-auto h-[340px] bg-[url('/assets/bg.svg')] bg-cover bg-center mb-32 md:mb-20 sm:mb-16 ml:mb-10 mm:mb-10 ms:mb-10">
        <img
          src="/assets/orange.svg"
          width={470}
          height={200}
          alt="orange"
          className="absolute top-12 z-1  sm:w-[400px] ml:w-[250px] mm:w-[250px] ms:w-[250px]"
        />
        <img
          src="/assets/orange.svg"
          width={526}
          height={306}
          alt="orange"
          className="absolute z-1 scale-x-[-1] right-0 mt-16 md:w-72 sm:w-[300px] md:top-40 sm:top-[160px] ml:top-[170px] mm:top-[170px] ms:top-[170px] ml:w-[350px] mm:w-[350px] ms:w-[300px] "
        />
        <h1 className="pl-12 ml:pl-4 mm:pl-4 ms:pl-4 w-[55%] ml:w-[100%] mm:w-[100%] ms:w-[100%] ml:pt-4 mm:pt-4 ms:pt-4 pt-16 text-3xl text-white z-10 font-bold lg:text-2xl md:text-[20px] sm:text-[20px] ml:text-[16px] mm:text-[14px] ms:text-[14px]">
          {t("about.title")}
        </h1>
        <div className="flex w-[45%] ml:w-[100%] mm:w-[100%] ms:w-[100%] flex-col justify-center items-center h-[100%] text-center gap-1 z-10">
          <img
            src="/assets/enot-text.svg"
            width={104}
            height={27}
            alt="enot-text"
            className=""
          />
          <img
            src="/assets/logo-white.svg"
            width={185}
            height={185}
            alt="logo-white"
            className="sm:w-[140px] ml:w-[100px] mm:w-[90px] ms:w-[80px] md:w-[150px]"
          />
          <h1 className="text-xl text-white font-bold w-64">
            {t("about.title2")}
          </h1>
        </div>
      </div>
      <div className="xl:px-10 px-5 py-3 flex flex-col gap-5 mb-28 sm:mb-16 ml:mb-10 mm:mb-8 ms:mb-5 mm:px-5 mm:py-5 ms:px-5 ms:py-5">
        <h1 className="font-bold text-2xl">{t("about.title3")}</h1>
        <h2 className="font-bold text-xl">{t("about.desc")}</h2>
        <div className="flex justify-between sm:gap-2 ml:gap-2 mm:gap-2 ms:gap-2 mm:flex-col ms:flex-col">
          <div className="flex relative xl:left-16 ml:w-[50%]  flex-col gap-10 xl:gap-24 z-10 ml:gap-5 mm:gap-5 ms:gap-5">
            <div
              data-aos="fade-right"
              className="flex ml:min-h-[200px] relative min-h-[190px] sm:min-h-[214px] left-28 md:left-10 sm:left-0 ml:left-0 mm:left-0 ms:left-0 mm:min-h-[120px] ms:min-h-[120px] rounded-lg bg-[#222] shadow-md text-white max-w-[408px] max-h-[226px] p-5 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition border"
            >
              <div className="flex flex-col gap-5">
                <h1 className="font-bold text-xl">{t("about.t1")}</h1>
                <p className="lg:text-xs mm:text-[9px] ml:text-[9px] ms:text-[9px]">
                  {t("about.t2")}
                </p>
              </div>
              <img
                src="/assets/about-c1.svg"
                alt="image"
                width={170}
                height={0}
                className="hidden xl:block lg:block lg:w-[120px]"
              />
            </div>
            <div
              data-aos="fade-right"
              className="flex ml:min-h-[200px] rounded-lg sm:min-h-[214px] mm:min-h-[120px] ms:min-h-[120px] min-h-[190px] bg-[#222] shadow-md text-white max-w-[408px] max-h-[226px] p-5 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition border"
            >
              <div className="flex flex-col gap-5">
                <h1 className="font-bold text-xl">{t("about.t3")}</h1>
                <p className="lg:text-xs mm:text-[9px] ml:text-[9px] ms:text-[9px]">
                  {t("about.t4")}
                </p>
              </div>
              <img
                src="/assets/about-c2.svg"
                alt="image"
                width={170}
                height={0}
                className="hidden xl:block lg:block lg:w-[120px]"
              />
            </div>
            <div
              data-aos="fade-right"
              className="flex ml:min-h-[200px] relative sm:min-h-[214px] mm:min-h-[120px] ms:min-h-[120px] min-h-[190px] left-24 md:left-10 sm:left-0 ml:left-0 mm:left-0 ms:left-0 xl:top-[80px] rounded-lg bg-[#222] shadow-md text-white max-w-[408px] max-h-[226px] p-5 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition border"
            >
              <div className="flex flex-col gap-5">
                <h1 className="font-bold text-xl">{t("about.t5")}</h1>
                <p className="lg:text-xs mm:text-[9px] ml:text-[9px] ms:text-[9px]">
                  {t("about.t6")}
                </p>
              </div>
              <img
                src="/assets/about-c3.svg"
                alt="image"
                width={170}
                height={0}
                className="hidden xl:block lg:block lg:w-[120px]"
              />
            </div>
          </div>
          <div className="xl:mt-[210px] mt-[100px] relative sm:hidden ml:hidden mm:hidden ms:hidden">
            <div className="flex xl:gap-5 z-1 lg:mr-28">
              <img
                src="/assets/about-arrow-middle.svg"
                alt="vector"
                className="relative w-[100%] xl:top-20 xl:left-6 lg:top-40 md:ml-[-95px] md:top-28"
              />
              <img
                src="/assets/about-arrow-top.svg"
                alt="vector"
                className="relative w-[100%]"
              />
              <img
                src="/assets/about-arrow-top.svg"
                alt="vector"
                className="relative w-[100%] mr-1 scale-x-[-1]"
              />
              <img
                src="/assets/about-arrow-middle.svg"
                alt="vector"
                className="relative w-[100%] scale-x-[-1] xl:top-20 xl:right-6 lg:top-40 lg:right-4 md:top-28"
              />
            </div>
            <div className="flex items-center justify-center relative z-100">
              <div className="flex flex-col max-w-[276px] max-h-[240px] gap-4 items-center bg-white border xl:p-16 md:p-10 md:px-[30px] md:py-[20px] px-[50px] py-[40px] rounded-[50%]">
                <img
                  src="/assets/enot-text-black.svg"
                  width={86}
                  height={23}
                  alt="enot-text"
                  className="w-[46px] h-[13px]"
                />
                <img
                  src="/assets/out-logo.svg"
                  width={130}
                  height={130}
                  alt="logo-white"
                  className="w-[100px] h-[100px] "
                />
              </div>
            </div>
            <div className="flex gap-40 md:gap-10 md:mt-0 mt-[-30px] justify-center z-10">
              <img
                src="/assets/about-arrow-bottom.svg"
                alt="vector"
                className="relative w-[75px]"
              />
              <img
                src="/assets/about-arrow-bottom.svg"
                alt="vector"
                className="relative w-[75px] mr-1 scale-x-[-1]"
              />
            </div>
          </div>
          <div className="flex relative xl:right-16 flex-col gap-10 xl:gap-24 z-10 ml:gap-5 mm:gap-5 ms:gap-5">
            <div
              data-aos="fade-left"
              className="flex ml:min-h-[200px] sm:min-h-[214px] relative mm:min-h-[120px] ms:min-h-[120px] right-28 min-h-[190px] md:right-10 sm:right-0 ml:right-0 mm:right-0 ms:right-0 rounded-lg bg-[#222] shadow-md text-white max-w-[408px] max-h-[226px] p-5 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition border"
            >
              <div className="flex flex-col gap-5">
                <h1 className="font-bold text-xl">{t("about.t7")}</h1>
                <p className="lg:text-xs mm:text-[9px] ml:text-[9px] ms:text-[9px]">
                  {t("about.t8")}
                </p>
              </div>
              <img
                src="/assets/about-c4.svg"
                alt="image"
                width={170}
                height={0}
                className="hidden xl:block lg:block lg:w-[120px]"
              />
            </div>
            <div
              data-aos="fade-left"
              className="flex ml:min-h-[200px] sm:min-h-[214px] rounded-lg mm:min-h-[120px] ms:min-h-[120px] min-h-[190px] bg-[#222] shadow-md text-white max-w-[408px] max-h-[226px] p-5 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition border"
            >
              <div className="flex flex-col gap-5">
                <h1 className="font-bold text-xl">{t("about.t9")}</h1>
                <p className="lg:text-xs mm:text-[9px] ml:text-[9px] ms:text-[9px]">
                  {t("about.t10")}
                </p>
              </div>
              <img
                src="/assets/about-c5.svg"
                alt="image"
                width={170}
                height={0}
                className="hidden xl:block lg:block lg:w-[120px]"
              />
            </div>
            <div
              data-aos="fade-left"
              className="flex ml:min-h-[200px] sm:min-h-[214px] relative mm:min-h-[120px] ms:min-h-[120px] min-h-[190px] right-24 md:right-10 sm:right-0 ml:right-0 mm:right-0 ms:right-0  xl:top-[70px] rounded-lg bg-[#222] shadow-md text-white max-w-[408px] max-h-[226px] p-5 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition border"
            >
              <div className="flex flex-col gap-5">
                <h1 className="font-bold text-xl">{t("about.t11")}</h1>
                <p className="lg:text-xs mm:text-[9px] ml:text-[9px] ms:text-[9px]">
                  {t("about.t12")}
                </p>
              </div>
              <img
                src="/assets/about-c6.svg"
                alt="image"
                width={170}
                height={0}
                className="hidden xl:block lg:block lg:w-[120px]"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="xl:px-10 p-5 py-3 flex flex-col gap-5 mb-52">
        <h1 className="font-bold text-2xl">{t("about.t13")}</h1>
        <div className="z-10">
          <div className="pt-12 flex justify-between ml:flex-col mm:flex-col ms:flex-col">
            <div className="flex flex-col justify-center gap-3 ml:mt-[-50px] ml:ml-[-200px]  mm:mt-[-50px] mm:ml-[-200px]  ms:mt-[-50px] ms:ml-[-200px] items-center hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition">
              <img
                src="/assets/about-user.svg"
                width={146}
                height={146}
                alt="image"
                className="lg:w-28 lg:h-28 md:w-20 md:h-20 sm:w-20 sm:h-20 ml:w-20 ml:h-20 mm:w-20 mm:h-20 ms:w-20 ms:h-20"
              />
              <span className="px-5 py-3 md:px-3 md:py-1 md:text-sm sm:px-1 sm:py-1 sm:text-sm ml:px-2 ml:py-1 ml:text-sm mm:px-2 mm:py-1 mm:text-sm ms:px-2 ms:py-1 ms:text-sm  bg-about text-white rounded-xl lg:text-sm">
                Бухгалтер
              </span>
            </div>
            <span className="relative ml:mt-[-250px] ml:ml-[180px] mm:mt-[-220px] mm:ml-[180px] ms:mt-[-250px] ms:ml-[130px]  md:text-sm lg:text-sm sm:text-sm ml:text-sm mm:text-sm ms:text-sm ml:w-[130px] mm:w-[130px] ms:w-[130px] lg:top-36 md:top-32 sm:top-32 px-5 md:px-2 sm:px-2 h-[48px] py-2 border-[#FC3100] bg-black border-4 text-white rounded-xl top-44 xl:right-16 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition">
              {t("about.t133")}
            </span>
            <div className="relative ml:mt-[100px] ml:ml-[200px] mm:mt-[100px] mm:ml-[200px] ms:mt-[100px] ms:ml-[200px] text-center flex flex-col justify-center gap-3 items-center top-28 xl:right-32 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition">
              <img
                src="/assets/about-enot.svg"
                width={146}
                height={146}
                alt="image"
                className="lg:w-28 lg:h-28 md:w-20 md:h-20 sm:w-20 sm:h-20 ml:w-20 ml:h-20 mm:w-20 mm:h-20 ms:w-20 ms:h-20"
              />
              <span className="px-5 py-3 md:px-3 md:py-1 md:text-sm sm:px-1 sm:py-1 sm:text-sm ml:px-2 ml:py-1 ml:text-sm mm:px-2 mm:py-1 mm:text-sm ms:px-2 ms:py-1 ms:text-sm bg-about text-white rounded-xl lg:text-sm">
                Оператор ЕНОТ
              </span>
            </div>
            <span className="relative ml:mt-[0px] ml:ml-[180px] mm:mt-[-30px] mm:ml-[140px] ms:mt-[0px] ms:ml-[130px] md:text-sm lg:text-sm sm:text-sm ml:text-sm mm:text-sm ms:text-sm lg:top-20 md:top-20 sm:top-20 sm:right-5 md:right-5 px-5 md:px-2 sm:px-2 h-[48px] ml:w-[130px] mm:w-[130px] ms:w-[130px] py-2 border-[#FC3100] bg-black border-4 text-white rounded-xl top-44 xl:right-48 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition">
              {t("about.t1333")}
            </span>
            <div className="relative ml:mt-[80px] ml:ml-[-230px] mm:mt-[80px] mm:ml-[-250px] ms:mt-[40px] ms:ml-[-200px] flex flex-col justify-center gap-3 items-center xl:right-56 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition">
              <img
                src="/assets/about-user.svg"
                width={146}
                height={146}
                alt="image"
                className="lg:w-28 lg:h-28 md:w-20 md:h-20 sm:w-20 sm:h-20 ml:w-20 ml:h-20 mm:w-20 mm:h-20 ms:w-20 ms:h-20"
              />
              <span className="px-5 py-3 md:px-3 md:py-1 md:text-sm sm:px-1 sm:py-1 sm:text-sm ml:px-2 ml:py-1 ml:text-sm mm:px-2 mm:py-1 mm:text-sm ms:px-2 ms:py-1 ms:text-sm bg-about text-white rounded-xl lg:text-sm">
                Инспектор
              </span>
            </div>
            <div className="relative ms:mt-[-40px] text-center flex flex-col justify-center gap-3 sm:top-28 items-center top-40 xl:right-16 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition">
              <img
                src="/assets/about-illustration.svg"
                width={146}
                height={146}
                alt="image"
                className="lg:w-28 lg:h-28 md:w-20 md:h-20 sm:w-20 sm:h-20 ml:w-20 ml:h-20 mm:w-20 mm:h-20 ms:w-20 ms:h-20"
              />
              <span className="px-5 py-3 md:px-3 md:py-1 md:text-sm sm:px-1 sm:py-1 sm:text-sm ml:px-2 ml:py-1 ml:text-sm mm:px-2 mm:py-1 mm:text-sm ms:px-2 ms:py-1 ms:text-sm bg-about text-white rounded-xl lg:text-sm">
                {t("about.t14")}
              </span>
            </div>
          </div>
        </div>
        <img
          src="/assets/about-line.svg"
          alt="image"
          className="absolute z-1 w-[full] xl:w-[95%] p-36 lg:p-24 md:p-20 sm:p-16 lg:mt-12 md:mt-20 sm:mt-24 lg:w-[95%] md:w-[95%] sm:w-[95%] ml:hidden mm:hidden ms:hidden"
        />
        <img
          src="/assets/about-line2.svg"
          alt="image"
          className="absolute hidden z-1 w-[full] ms:w-[90%] mt-20 ml:block mm:block ms:block"
        />
      </div>
      <div className="flex items-center justify-around max-w-[725px] md:max-w-[600px] ml:max-w-[400px] px-6 py-4 text-white ml-[-40px] rounded-tr-[500px] rounded-br-[500px] bg-gradient-custom mb-[80px]">
        <h1 className="font-bold text-xl ml:text-sm mm:text-sm ms:text-sm ml-10">
          {t("about.t15")} <br /> {t("about.t16")}
        </h1>
        <img
          src="/assets/logo-white.svg"
          width={113}
          height={113}
          alt="logo"
          className="relative items-end ml:left-20"
        />
      </div>
      <div className="px-14 mm:px-5 ms:px-5 ml:px-5 py-3 grid grid-cols-2 justify-center gap-8 mb-16 ml:flex ml:flex-col mm:flex mm:flex-col ms:flex ms:flex-col">
        <div
          data-aos="fade-right"
          className="max-w-[578px] ml:flex-col p-4 border-2 border-[#FC3100] rounded-xl hover:scale-103 hover:-translate-y-1 transition"
        >
          <div className="relative top-[-30px] left-[-30px]">
            <span className="absolute text-white fzxddcont-bold text-lg rounded-full bg-[#FC3100] w-[30px] h-[30px] text-center">
              1
            </span>
          </div>
          <h1 className="font-bold text-lg mb-2">{t("about.t17")}</h1>
          <p>{t("about.t18")}</p>
        </div>
        <div
          data-aos="fade-right"
          className="max-w-[578px] p-4 border-2 border-[#FC3100] rounded-xl hover:scale-103 hover:-translate-y-1 transition"
        >
          <div className="relative top-[-30px] left-[-30px]">
            <span className="absolute text-white font-bold text-lg rounded-full bg-[#FC3100] w-[30px] h-[30px] text-center">
              2
            </span>
          </div>
          <h1 className="font-bold text-lg mb-2">{t("about.t19")}</h1>
          <p>{t("about.t20")}</p>
        </div>
        <div
          data-aos="fade-right"
          className="max-w-[578px] p-4 border-2 border-[#FC3100] rounded-xl hover:scale-103 hover:-translate-y-1 transition"
        >
          <div className="relative top-[-30px] left-[-30px]">
            <span className="absolute text-white font-bold text-lg rounded-full bg-[#FC3100] w-[30px] h-[30px] text-center">
              3
            </span>
          </div>
          <h1 className="font-bold text-lg mb-2">{t("about.t21")}</h1>
          <p>{t("about.t22")}</p>
        </div>
        <div
          data-aos="fade-right"
          className="max-w-[578px] p-4 border-2 border-[#FC3100] rounded-xl hover:scale-103 hover:-translate-y-1 transition"
        >
          <div className="relative top-[-30px] left-[-30px]">
            <span className="absolute text-white font-bold text-lg rounded-full bg-[#FC3100] w-[30px] h-[30px] text-center">
              4
            </span>
          </div>
          <h1 className="font-bold text-lg mb-2">{t("about.t23")}</h1>
          <p>{t("about.t24")}</p>
        </div>
      </div>
      <div className="flex justify-between px-10 ml:px-2 mm:px-2 md:px-2 ms:px-2 sm:flex-col mb-20 ml:flex-col mm:flex-col ms:flex-col">
        <div className="flex items-center justify-around w-[351px] md:max-w-[200px] px-6 py-4 ml-[-40px] text-white rounded-tr-[500px] rounded-br-[500px] bg-gradient-custom mb-[80px]">
          <h1 className="font-bold text-lg py-12 ml:py-2 mm:py-2 ms:py-2 md:ml-5">
            {t("about.t25")}
          </h1>
        </div>
        <div className="flex gap-20 sm:gap-0 ml:gap-0 mm:gap-0 ms:gap-0 ">
          <div className="max-w-[218px] flex flex-col items-center gap-7 text-center">
            <img
              src="/assets/about-nalog.png"
              width={110}
              height={110}
              alt="gos logo"
              className="ml:w-[80px] mm:w-[70px] ms:w-[70px]"
            />
            <p className="ml:text-sm mm:text-[9px] ms:text-[9px]">
              {t("about.t26")}
            </p>
          </div>
          <div className="max-w-[218px] flex flex-col items-center gap-7 text-center">
            <img
              src="/assets/about-fond.png"
              width={110}
              height={110}
              alt="gos logo"
              className="ml:w-[80px] mm:w-[70px] ms:w-[70px]"
            />
            <p className="ml:text-sm mm:text-[9px] ms:text-[9px]">
              {t("about.t27")}
            </p>
          </div>
          <div className="max-w-[218px] flex flex-col items-center gap-7 text-center">
            <img
              src="/assets/about-stat.png"
              width={110}
              height={110}
              alt="gos logo"
              className="ml:w-[80px] mm:w-[70px] ms:w-[70px]"
            />
            <p className="ml:text-sm mm:text-[9px] ms:text-[9px]">
              {t("about.t28")}
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-10 px-10 mb-20 ml:px-2 mm:px-2 ms:px-2">
        <h1 className="font-bold text-2xl">{t("about.t29")}</h1>
        <div className="grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 mm:grid-cols-2 ms:grid-cols-2 ml:grid-cols-2  mm:text-[11px] ms:text-[10px] ml:text-[13px] gap-10 ml:gap-4 mm:gap-4 ms:gap-4 z-10 justify-self-center items-center">
          <div className="flex flex-col justify-between p-4 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p>{t("about.t30")}</p>
            <a
              target="_blank"
              href="/pdf/instrENOT.pdf"
              className="flex justify-end top-full"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10 md:mt-[-30px] sm:mt-[-30px]"
              />
            </a>
          </div>
          <div className="flex flex-col justify-between p-4 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p>{t("about.t31")}</p>
            <a
              target="_blank"
              href="/pdf/MyInstrEnot.pdf"
              className="flex justify-end top-full"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10 md:mt-[-30px] sm:mt-[-30px]"
              />
            </a>
          </div>
          <div className="flex flex-col justify-between p-4 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p>{t("about.t32")}</p>
            <a
              target="_blank"
              href="/pdf/otchetnostENOT.pdf"
              className="flex justify-end mt-[-30px]"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10 md:mt-[-30px] sm:mt-[-30px]"
              />
            </a>
          </div>
          <div className="flex flex-col justify-between p-4 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p>{t("about.t33")}</p>
            <a
              target="_blank"
              href="/pdf/instrplatejMjbilnik.pdf"
              className="flex justify-end top-full"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10 md:mt-[-30px] sm:mt-[-30px]"
              />
            </a>
          </div>
        </div>
        <div className="flex items-center justify-center z-10">
          <div className="flex flex-col text-center justify-between p-4 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <h1 className="text-lg font-bold">{t("about.t34")}</h1>
            <div className="flex items-center">
              <p className="w-[100%]">{t("about.t35")}</p>
              <a
                target="_blank"
                href="/pdf/Tarif2019.pdf"
                className="flex justify-end"
              >
                <img
                  src="/assets/arrow.svg"
                  alt="arrow"
                  className="w-10 h-10"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <img
        src="/assets/orange.svg"
        width={526}
        height={306}
        alt="orange"
        className="absolute z-1 scale-x-[-1] right-0 mt-[-200px] md:w-[400px] sm:w-[400px] ml:w-[400px] mm:w-[350px] ms:w-[300px] ml:mt-[350px] mm:mt-[350px] ms:mt-[350px]"
      />
      <div className="relative px-10 mb-10 z-10 ml:px-5 mm:px-5 ms:px-5">
        <h1 className="font-bold text-2xl mb-10">{t("about.t36")}</h1>
        <div className="flex justify-between gap-5 sm:flex-col ml:flex-col mm:flex-col ms:flex-col items-center">
          <div className="flex w-[60%] sm:w-[100%] ml:w-[100%] mm:w-[100%] ms:w-[100%] flex-col gap-16">
            <div className="flex justify-between ml:gap-5 mm:gap-5 ms:gap-4">
              <div className="flex gap-3 flex-col items-center ml:w-[33%] mm:w-[33%] ms:2-[33%]">
                <a
                  href="http://www.instagram.com/ubr.kg/"
                  className="bg-black w-8 rounded-full p-1"
                >
                  <InstagramIcon className="text-white" />
                </a>
                <img
                  src="/assets/qr-inst.svg"
                  className="w-[165px] md:w-[100px]"
                  alt="inst"
                />
              </div>
              <div className="flex gap-3 flex-col items-center ml:w-[33%] mm:w-[33%] ms:2-[33%]">
                <a
                  href="https://www.facebook.com/ubr.kg"
                  className="bg-black w-8 rounded-full p-1"
                >
                  <FacebookIcon className="text-white" />
                </a>
                <img
                  src="/assets/qr-face.svg"
                  className="w-[165px] md:w-[100px]"
                  alt="inst"
                />
              </div>
              <div className="flex gap-3 flex-col items-center ml:w-[33%] mm:w-[33%] ms:2-[33%]">
                <a
                  href="https://t.me/joinchat/AAAAAFITwyNXJDg6uy2FlA"
                  className="bg-black w-8 rounded-full p-1"
                >
                  <TelegramIcon className="text-white" />
                </a>
                <img
                  src="/assets/qr-tg.svg"
                  className="w-[165px] md:w-[100px]"
                  alt="inst"
                />
              </div>
            </div>
            <div className="font-bold flex gap-40 md:gap-5 ml:gap-5 mm:gap-5 ms:gap-5">
              <div className="flex gap-2 ml:flex-col mm:flex-col ms:flex-col">
                <div className="bg-black w-8 h-8 rounded-full p-1">
                  <LocalPhoneIcon className="text-white" />
                </div>
                <div className="flex flex-col gap-2">
                  <a href="tel:+996312986866" className="hover:text-[#FC3100]">
                    (0312) 98 58 66
                  </a>
                  <a href="tel:+996755985866" className="hover:text-[#FC3100]">
                    (0755) 98 58 66
                  </a>
                  <a href="tel:+996501985866" className="hover:text-[#FC3100]">
                    (0501) 98 58 66
                  </a>
                  <a href="tel:+996772985866" className="hover:text-[#FC3100]">
                    (0772) 98 58 66
                  </a>
                </div>
              </div>
              <div className="">
                <div className="flex gap-2 items-center mb-12 ml:mb-7 mm:mb-7 ms:mb-7 ml:flex-col mm:flex-col ms:flex-col">
                  <div className="bg-black w-8 rounded-full p-1">
                    <WhatsAppIcon className="text-white" />
                  </div>
                  <a
                    href="https://wa.link/nt2s8m"
                    className="hover:text-[#FC3100]"
                  >
                    (0708) 10 00 84
                  </a>
                </div>
                <div className="flex gap-2 items-center ml:flex-col mm:flex-col ms:flex-col">
                  <div className="bg-black w-8 rounded-full p-1">
                    <EmailIcon className="text-white" />
                  </div>
                  <a href="sale@ubr.kg" className="hover:text-[#FC3100]">
                    sale@ubr.kg
                  </a>
                </div>
              </div>
            </div>
          </div>
          <ModalContact />
        </div>
      </div>
    </>
  );
};

export default AboutCom1;
