import BlockEnd from '../BlockEnd';
import { useTranslation } from 'react-i18next';

const InfoCom1 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="px-10 mm:px-5 ms:px-5 ml:px-5  py-3 flex flex-col gap-5">
        <h1 className="font-bold text-2xl"> {t('info.title')}</h1>
        <h2 className="font-bold text-xl"> {t('info.desc')}</h2>
        <img
          src="/assets/orange.svg"
          width={900}
          height={400}
          alt="orange"
          className="absolute z-1 left-0 top-48 lg:w-[800px] ms:mt-5"
        />
        <img
          src="/assets/orange.svg"
          width={700}
          height={350}
          alt="orange"
          className="absolute z-1 scale-x-[-1] right-0 top-[600px] lg:w-[600px] md:top-[800px] sm:top-[700px]"
        />
        <div className="grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 mm:grid-cols-2 ms:grid-cols-2 ml:grid-cols-2  mm:text-[11px] ms:text-[10px] ml:text-[13px] gap-10 ml:gap-4 mm:gap-4 ms:gap-4 z-10 mb-20 justify-self-center items-center">
          <div className="flex flex-col justify-between p-4 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p> {t('info.t1')}</p>
            <a
              href="/pdf/instrJC.pdf"
              target="_blank"
              download
              className="flex justify-end top-full"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10 md:mt-[-30px] sm:mt-[-30px]"
              />
            </a>
          </div>
          <div className="flex flex-col justify-between p-5 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p> {t('info.t2')}</p>
            <a
              href="/pdf/instrrazbrutok.pdf"
              target="_blank"
              download
              className="flex justify-end"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10"
              />
            </a>
          </div>
          <div className="flex flex-col justify-between p-4 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p> {t('info.t3')}</p>
            <a
              href="/pdf/UsersInstructionsENOT.pdf"
              target="_blank"
              download
              className="flex justify-end"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10"
              />
            </a>
          </div>
          <div className="flex flex-col justify-between p-4 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p> {t('info.t4')}</p>
            <a
              href="/pdf/CheckECP.pdf"
              target="_blank"
              download
              className="flex justify-end"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10"
              />
            </a>
          </div>
          <div className="flex flex-col justify-between p-4 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p> {t('info.t5')}</p>
            <a
              href="/pdf/ChistkaKESH.pdf"
              target="_blank"
              download
              className="flex justify-end"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10"
              />
            </a>
          </div>
          <div className="flex flex-col justify-between p-4 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p> {t('info.t6')}</p>
            <a
              href="/pdf/instr062.pdf"
              target="_blank"
              download
              className="flex justify-end"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10"
              />
            </a>
          </div>
          <div className="flex flex-col justify-between p-4 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p> {t('info.t7')}</p>
            <a
              href="/pdf/OtpravkapervonotchvGNS.pdf"
              target="_blank"
              download
              className="flex justify-end"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10"
              />
            </a>
          </div>
          <div className="flex flex-col justify-between p-4 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p> {t('info.t8')}</p>
            <a
              href="/pdf/SFinstr.pdf"
              target="_blank"
              download
              className="flex justify-end"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10"
              />
            </a>
          </div>
          <div className="flex flex-col justify-between p-4 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p> {t('info.t9')}</p>
            <a
              href="/pdf/SmenaRekvizitoviPIN.pdf"
              target="_blank"
              download
              className="flex justify-end"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10"
              />
            </a>
          </div>
          <div className="flex flex-col justify-between p-4 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p> {t('info.t10')}</p>
            <a
              href="/pdf/InstrpoRutokvhod.pdf"
              target="_blank"
              download
              className="flex justify-end"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10"
              />
            </a>
          </div>
          <div className="flex flex-col justify-between p-4 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p> {t('info.t11')}</p>
            <a
              href="/pdf/InstrpereustPlag.pdf"
              target="_blank"
              download
              className="flex justify-end"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10"
              />
            </a>
          </div>
          <div className="flex flex-col justify-between p-4 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p> {t('info.t12')}</p>
            <a
              href="/pdf/Instrustroshibplagin.pdf"
              target="_blank"
              download
              className="flex justify-end"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10"
              />
            </a>
          </div>
          <div className="flex flex-col justify-between p-4 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p> {t('info.t13')}</p>
            <a
              href="https://anydesk.com/ru/downloads/windows"
              target="_blank"
              download
              className="flex relative justify-end"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10"
              />
            </a>
          </div>
          <div className="flex flex-col justify-between p-4 max-w-[280px] h-40 shadow-md border bg-white rounded-xl hover:border-[#FF4012] hover:scale-105 hover:-translate-y-1 transition">
            <p> {t('info.t14')}</p>
            <a
              href="https://check.ubr.kg/"
              target="_blank"
              download
              className="flex justify-end"
            >
              <img
                src="/assets/arrow.svg"
                alt="arrow"
                className="w-10 h-10"
              />
            </a>
          </div>
        </div>
        <h1 className="font-bold text-xl max-w-[680px] mb-20">
          {' '}
          {t('info.t15')}
        </h1>
        <h1 className="font-bold text-2xl mb-4"> {t('info.t16')}</h1>
        <div className="flex flex-col gap-8 items-center">
          <div data-aos="fade-right" className="">
            <h2 className="font-bold text-xl mb-4"> {t('info.t17')}</h2>
            <div className="flex gap-10 md:flex-col sm:flex-col mm:flex-col ms:flex-col ml:flex-col ">
              <div className="flex rounded-lg bg-[#222] shadow-md text-white max-w-[578px] max-h-[257px] p-5 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition border lg:w-[50%]">
                <div className="flex flex-col gap-5">
                  <a href="https://infocom.kg/cert/kid783AA1368A1B72C94BE3FDB6B552C09023B5E139/issuer.crt">
                    <h1 className="font-bold underline"> {t('info.t18')}</h1>
                  </a>
                  <p className="lg:text-xs mm:text-[9px] ml:text-[9px] ms:text-[9px]">
                    {t('info.t19')}
                  </p>
                </div>
                <img
                  src="/assets/info1.svg"
                  alt="image"
                  width={200}
                  height={0}
                  className="mm:hidden ms:hidden"
                />
              </div>
              <div className="flex rounded-lg bg-[#222] shadow-md text-white max-w-[578px] max-h-[257px] p-5 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition border lg:w-[50%]">
                <div className="flex flex-col gap-5">
                  <a href="https://infocom.kg/cert/3338-kid783AA1368A1B72C94BE3FDB6B552C09023B5E139/revokedCerts.crl">
                    <h1 className="font-bold underline"> {t('info.t20')}</h1>
                  </a>
                  <p className="lg:text-xs mm:text-[9px] ml:text-[9px] ms:text-[9px]">
                    {t('info.t21')}
                  </p>
                </div>
                <img
                  src="/assets/info2.svg"
                  alt="image"
                  width={200}
                  height={0}
                  className="mm:hidden ms:hidden"
                />
              </div>
            </div>
          </div>
          <div data-aos="fade-right" className="">
            <h2 className="font-bold text-xl mb-4"> {t('info.t22')}</h2>
            <div className="flex gap-10 md:flex-col sm:flex-col mm:flex-col ms:flex-col ml:flex-col">
              <div className="flex rounded-lg bg-[#222] shadow-md text-white max-w-[578px] max-h-[257px] p-5 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition border lg:w-[50%]">
                <div className="flex flex-col gap-5">
                  <a href="/upload/file/SubUBR.cer" download>
                    <h1 className="font-bold underline"> {t('info.t23')}</h1>
                  </a>
                  <p className="lg:text-xs mm:text-[9px] ml:text-[9px] ms:text-[9px]">
                    {t('info.t24')}
                  </p>
                </div>
                <img
                  src="/assets/info3.svg"
                  alt="image"
                  width={200}
                  height={0}
                  className="mm:hidden ms:hidden"
                />
              </div>
              <div className="flex rounded-lg bg-[#222] shadow-md text-white max-w-[578px] max-h-[257px] p-5 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition border lg:w-[50%]">
                <div className="flex flex-col gap-5">
                  <a href="/upload/SUBCA/UBR.crl" download>
                    <h1 className="font-bold underline"> {t('info.t25')}</h1>
                  </a>
                  <p className="lg:text-xs mm:text-[9px] ml:text-[9px] ms:text-[9px]">
                    {t('info.t26')}
                  </p>
                </div>
                <img
                  src="/assets/info4.svg"
                  alt="image"
                  width={200}
                  height={0}
                  className="mm:hidden ms:hidden"
                />
              </div>
            </div>
          </div>
          <div data-aos="fade-right" className="">
            <h2 className="font-bold text-xl mb-4"> {t('info.t27')}</h2>
            <div className="flex gap-10 md:flex-col sm:flex-col mm:flex-col ms:flex-col ml:flex-col">
              <div className="flex rounded-lg bg-[#222] shadow-md text-white max-w-[578px] max-h-[257px] p-5 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition border lg:w-[50%]">
                <div className="flex flex-col gap-5">
                  <a href="/pdf/regla2021.pdf" target="_blank" download>
                    <h1 className="font-bold underline"> {t('info.t28')}</h1>
                  </a>
                  <p className="lg:text-xs mm:text-[9px] ml:text-[9px] ms:text-[9px]">
                    {t('info.t29')}
                  </p>
                </div>
                <img
                  src="/assets/info5.svg"
                  alt="image"
                  width={200}
                  height={0}
                  className="mm:hidden ms:hidden"
                />
              </div>
              <div className="flex rounded-lg bg-[#222] shadow-md text-white max-w-[578px] max-h-[257px] p-5 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition border lg:w-[50%]">
                <div className="flex flex-col gap-5">
                  <a href="/pdf/pologeniesubcaUBR2018.pdf" target="_blank" download>
                    <h1 className="font-bold underline"> {t('info.t30')}</h1>
                  </a>
                  <p className="lg:text-xs mm:text-[9px] ml:text-[9px] ms:text-[9px]">
                    {t('info.t31')}
                  </p>
                </div>
                <img
                  src="/assets/info6.svg"
                  alt="image"
                  width={200}
                  height={0}
                  className="mm:hidden ms:hidden"
                />
              </div>
            </div>
          </div>
          <div data-aos="fade-right" className="">
            <h2 className="font-bold text-xl mb-4"> {t('info.t32')}</h2>
            <div className="flex gap-10 md:flex-col sm:flex-col mm:flex-col ms:flex-col ml:flex-col">
              <div className="flex rounded-lg bg-[#222] shadow-md text-white max-w-[578px] max-h-[257px] p-5 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition border lg:w-[50%]">
                <div className="flex flex-col gap-5">
                  <a href="/pdf/JC-WebClient-4.3.4.1530.msi" download>
                    <h1 className="font-bold underline">JaCarta Web Client</h1>
                  </a>
                  <p className="lg:text-xs mm:text-[9px] ml:text-[9px] ms:text-[9px]">
                    {t('info.t33')}
                  </p>
                </div>
                <img
                  src="/assets/info7.svg"
                  alt="image"
                  width={200}
                  height={0}
                  className="mm:hidden ms:hidden"
                />
              </div>
              <div className="flex rounded-lg bg-[#222] shadow-md text-white max-w-[578px] max-h-[257px] p-5 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition border lg:w-[50%]">
                <div className="flex flex-col gap-5">
                  <a href="/pdf/jacartauc_3.0.1.3375_win.zip" download>
                    <h1 className="font-bold underline"> {t('info.t34')}</h1>
                  </a>
                  <p className="lg:text-xs mm:text-[9px] ml:text-[9px] ms:text-[9px]">
                    {t('info.t35')}
                  </p>
                </div>
                <img
                  src="/assets/info8.svg"
                  alt="image"
                  width={200}
                  height={0}
                  className="mm:hidden ms:hidden"
                />
              </div>
            </div>
          </div>
          <div
            data-aos="fade-right"
            className="flex rounded-lg bg-[#222] shadow-md text-white max-w-[578px] max-h-[257px] p-5 hover:border-[#FF4012] hover:scale-103 hover:-translate-y-1 transition border lg:w-[50%]"
          >
            <div className="flex flex-col gap-5">
              <a href="http://www.rutoken.ru/support/download/get/?fid=36" target="_blank">
                <h1 className="font-bold underline"> {t('info.t36')}</h1>
              </a>
              <p className="lg:text-xs mm:text-[9px] ml:text-[9px] ms:text-[9px]">
                {t('info.t37')}
              </p>
            </div>
            <img
              src="/assets/info9.svg"
              alt="image"
              width={200}
              height={0}
              className="mm:hidden ms:hidden"
            />
          </div>
        </div>
      </div>
      <BlockEnd />
    </>
  );
};

export default InfoCom1;
