import { auth } from '../../firebaseConfig';
import { useState } from 'react';
import {useTranslation} from 'react-i18next';
import { Alert, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };

    const handleClose2 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen2(false);
    };
  
  // Вход в Админ панель
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await auth.signInWithEmailAndPassword(email, password);
      setOpen(true);
      setTimeout(() => handleClose(), 3000)
      navigate('/admin');
    } catch (error) {
      setOpen2(true);
      setTimeout(() => handleClose2(), 3000)
      console.error('Login error:', error);
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 items-center">
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
      <div className="flex justify-center items-center">
        <img width={120} height={120} src="/assets/logo-white.svg" alt="logo admin" />
      </div>
      <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        {t('admin.loginTitle')}
      </h2>
    </div>
    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form className="space-y-6" action="#" method="POST" onSubmit={handleLogin}>
        <div>
          <label type="email" className="block text-sm font-medium leading-6 text-gray-900">
            Эл. почта
          </label>
          <div className="mt-2">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="block w-full min-w-[400px] ml:min-w-[350px] mm:min-w-[300px] ms:min-w-[270px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FF4012] sm:text-sm sm:leading-6"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between">
            <label type="password" className="block text-sm font-medium leading-6 text-gray-900">
              Пароль
            </label>
          </div>
          <div className="mt-2">
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#FF4012] sm:text-sm sm:leading-6"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-[#FF4012] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#ba4444] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t('admin.btn2')}
          </button>
        </div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Успешный вход!
          </Alert>
        </Snackbar>
        <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
          <Alert onClose={handleClose2} severity="error" sx={{ width: '100%' }}>
            Ошибка входа!
          </Alert>
        </Snackbar>
      </form>
    </div>
  </div>
  
  );
};

export default Login;
