import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

const MapCom = () => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const [isModalOpen3, setIsModalOpen3] = useState(false);

  const openModal3 = () => {
    setIsModalOpen3(true);
  };

  const closeModal3 = () => {
    setIsModalOpen3(false);
  };

  const [isModalOpen4, setIsModalOpen4] = useState(false);

  const openModal4 = () => {
    setIsModalOpen4(true);
  };

  const closeModal4 = () => {
    setIsModalOpen4(false);
  };

  const [isModalOpen5, setIsModalOpen5] = useState(false);

  const openModal5 = () => {
    setIsModalOpen5(true);
  };

  const closeModal5 = () => {
    setIsModalOpen5(false);
  };

  const [isModalOpen6, setIsModalOpen6] = useState(false);

  const openModal6 = () => {
    setIsModalOpen6(true);
  };

  const closeModal6 = () => {
    setIsModalOpen6(false);
  };

  const [isModalOpen7, setIsModalOpen7] = useState(false);

  const openModal7 = () => {
    setIsModalOpen7(true);
  };

  const closeModal7 = () => {
    setIsModalOpen7(false);
  };

  const [isModalOpen8, setIsModalOpen8] = useState(false);

  const openModal8 = () => {
    setIsModalOpen8(true);
  };

  const closeModal8 = () => {
    setIsModalOpen8(false);
  };

  const [isModalOpen9, setIsModalOpen9] = useState(false);

  const openModal9 = () => {
    setIsModalOpen9(true);
  };

  const closeModal9 = () => {
    setIsModalOpen9(false);
  };

  const [isModalOpen10, setIsModalOpen10] = useState(false);

  const openModal10 = () => {
    setIsModalOpen10(true);
  };

  const closeModal10 = () => {
    setIsModalOpen10(false);
  };

  const [isModalOpen11, setIsModalOpen11] = useState(false);

  const openModal11 = () => {
    setIsModalOpen11(true);
  };

  const closeModal11 = () => {
    setIsModalOpen11(false);
  };

  const [isModalOpen12, setIsModalOpen12] = useState(false);

  const openModal12 = () => {
    setIsModalOpen12(true);
  };

  const closeModal12 = () => {
    setIsModalOpen12(false);
  };

  const [isModalOpen13, setIsModalOpen13] = useState(false);

  const openModal13 = () => {
    setIsModalOpen13(true);
  };

  const closeModal13 = () => {
    setIsModalOpen13(false);
  };

  const [isModalOpen14, setIsModalOpen14] = useState(false);

  const openModal14 = () => {
    setIsModalOpen14(true);
  };

  const closeModal14 = () => {
    setIsModalOpen14(false);
  };

  const [isModalOpen15, setIsModalOpen15] = useState(false);

  const openModal15 = () => {
    setIsModalOpen15(true);
  };

  const closeModal15 = () => {
    setIsModalOpen15(false);
  };

  const [isModalOpen16, setIsModalOpen16] = useState(false);

  const openModal16 = () => {
    setIsModalOpen16(true);
  };

  const closeModal16 = () => {
    setIsModalOpen16(false);
  };

  const [isModalOpen17, setIsModalOpen17] = useState(false);

  const openModal17 = () => {
    setIsModalOpen17(true);
  };

  const closeModal17 = () => {
    setIsModalOpen17(false);
  };

  const [isModalOpen18, setIsModalOpen18] = useState(false);

  const openModal18 = () => {
    setIsModalOpen18(true);
  };

  const closeModal18 = () => {
    setIsModalOpen18(false);
  };

  const [isModalOpen19, setIsModalOpen19] = useState(false);

  const openModal19 = () => {
    setIsModalOpen19(true);
  };

  const closeModal19 = () => {
    setIsModalOpen19(false);
  };

  const [isModalOpen20, setIsModalOpen20] = useState(false);

  const openModal20 = () => {
    setIsModalOpen20(true);
  };

  const closeModal20 = () => {
    setIsModalOpen20(false);
  };

  const [isModalOpen21, setIsModalOpen21] = useState(false);

  const openModal21 = () => {
    setIsModalOpen21(true);
  };

  const closeModal21 = () => {
    setIsModalOpen21(false);
  };

  const [isModalOpen22, setIsModalOpen22] = useState(false);

  const openModal22 = () => {
    setIsModalOpen22(true);
  };

  const closeModal22 = () => {
    setIsModalOpen22(false);
  };

  const [isModalOpen23, setIsModalOpen23] = useState(false);

  const openModal23 = () => {
    setIsModalOpen23(true);
  };

  const closeModal23 = () => {
    setIsModalOpen23(false);
  };

  const [isModalOpen24, setIsModalOpen24] = useState(false);

  const openModal24 = () => {
    setIsModalOpen24(true);
  };

  const closeModal24 = () => {
    setIsModalOpen24(false);
  };

  const [isModalOpen25, setIsModalOpen25] = useState(false);

  const openModal25 = () => {
    setIsModalOpen25(true);
  };

  const closeModal25 = () => {
    setIsModalOpen25(false);
  };

  const [isModalOpen26, setIsModalOpen26] = useState(false);

  const openModal26 = () => {
    setIsModalOpen26(true);
  };

  const closeModal26 = () => {
    setIsModalOpen26(false);
  };

  const [isModalOpen27, setIsModalOpen27] = useState(false);

  const openModal27 = () => {
    setIsModalOpen27(true);
  };

  const closeModal27 = () => {
    setIsModalOpen27(false);
  };

  const [isModalOpen28, setIsModalOpen28] = useState(false);

  const openModal28 = () => {
    setIsModalOpen28(true);
  };

  const closeModal28 = () => {
    setIsModalOpen28(false);
  };

  return (
    <div>
      <div className="mt-[150px] relative">
        <img src="/assets/map.svg" alt="map" className="w-[100%]" />
        {/* Чуйская область */}
        <div className="lg:top-[7.5%]  absolute top-[7%] left-[45%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal}
              onMouseLeave={closeModal}
            />
            {isModalOpen && (
              <div
                onMouseEnter={openModal}
                onMouseLeave={closeModal}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[113%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://go.2gis.com/bo093"
                  className="absolute text-[10px] bottom-[80px] text-white bg-[#FF4012] left-[180px] p-2 text-center border-orange-400 border-2 rounded-md w-[120px]"
                >
                  {t('main.t9')}
                </a>
                <p className="text-[12px] font-bold">{t('t10')}</p>
                <p className="text-[11px]">
                  Адрес: г.Бишкек ул.Орозбекова, д. 2/2, офис. №7
                </p>
                <p className="text-[11px]">
                  {t('main.t11')}: Пн-Пт. с 9-00 до 18-00 {t('main.t12')}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="lg:top-[3%] lg:left-[45%] absolute top-[5%] left-[45%] ">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0  cursor-pointer"
              onMouseEnter={openModal2}
              onMouseLeave={closeModal2}
            />
            {isModalOpen2 && (
              <div
                onMouseEnter={openModal2}
                onMouseLeave={closeModal2}
                className="z-10 absolute transform -translate-x-[50%] -translate-y-[109%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://go.2gis.com/0ppnbr"
                  className="absolute text-[10px] bottom-[-14px] text-white bg-[#FF4012] left-[130px] p-2 text-center border-orange-400 border-2 rounded-md w-[120px]"
                >
                  {t('main.t9')}
                </a>
                <p className="text-[12px] font-bold">Офис ЦОП</p>
                <p className="text-[11px]">
                  Адрес: г.Бишкек ул. Ибраимова, д. 152, 2-этаж пересекает
                  просп. Жибек-Жолу
                </p>
                <p className="text-[11px]">
                  {t('main.t11')}: Пн-Пт. с 9-00 до 18-00 {t('main.t12')}.
                </p>
                <div className="flex justify-start flex-col">
                  <span className="text-[11px]">Телефон :</span>
                  <a
                    href="tel:+996709137370"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0709 137 370
                  </a>
                  <a
                    href="tel:+996777563956"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0777 563 956
                  </a>
                  <a
                    href="tel:+996701665244"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0701 665 244
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="lg:top-[7.4%] lg:left-[48%] absolute top-[7.4%] left-[46%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal3}
              onMouseLeave={closeModal3}
            />
            {isModalOpen3 && (
              <div
                onMouseEnter={openModal3}
                onMouseLeave={closeModal3}
                className="z-10 absolute transform -translate-x-[50%] -translate-y-[109%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://go.2gis.com/fi4gz"
                  className="absolute text-[10px] bottom-[70px] text-white bg-[#FF4012] left-[180px] p-2 text-center border-orange-400 border-2 rounded-md w-[120px]"
                >
                  {t('main.t9')}
                </a>
                <p className="text-[12px] font-bold">
                  Офис УГНС Свердловского района
                </p>
                <p className="text-[11px]">
                  Адрес: г. Бишкек, просп. Шабдан-Баатыра (просп. Чуй, район
                  ТЭЦ), д. 4/1,
                </p>
                <p className="text-[11px]">
                  {t('main.t11')}: Пн-Пт. с 9-00 до 18-00.
                </p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996221002642"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0221 002 642
                  </a>
                  <a
                    href="tel:+996777563956"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0777 563 956
                  </a>
                  <a
                    href="tel:+996701665244"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0701 665 244
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="lg:left-[43.5%]  absolute top-[7%] left-[44%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal4}
              onMouseLeave={closeModal4}
            />
            {isModalOpen4 && (
              <div
                onMouseEnter={openModal4}
                onMouseLeave={closeModal4}
                className="z-10 absolute transform -translate-x-[50%] -translate-y-[109%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://go.2gis.com/jnlfn"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[120px]"
                >
                  {t('main.t9')}
                </a>
                <p className="text-[12px] font-bold">
                  Офис УГНС Ленинского района
                </p>
                <p className="text-[11px]">
                  Адрес: г.Бишкек ул.Ала-Арчинская 71
                </p>
                <p className="text-[11px]">
                  График работы Пн-Пт с 9:00 до 18:00 без перерыва
                </p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996708892525"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0708 892 525
                  </a>
                  <a
                    href="tel:+996777563956"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0777 563 956
                  </a>
                  <a
                    href="tel:+996701665244"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0701 665 244
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="lg:top-[15%]  absolute top-[12%] left-[45.5%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal5}
              onMouseLeave={closeModal5}
            />
            {isModalOpen5 && (
              <div
                onMouseEnter={openModal5}
                onMouseLeave={closeModal5}
                className="z-10 absolute transform -translate-x-[50%] -translate-y-[111%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://go.2gis.com/r9q1g"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[120px]"
                >
                  {t('main.t9')}
                </a>
                <p className="text-[12px] font-bold">
                  Офис УГНС Октябрьский район
                </p>
                <p className="text-[11px]">Адрес: г. Бишкек, 10 мкр., дом 29</p>
                <p className="text-[11px]">{t('t11')}: с 09-00 до 18-00</p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996502077677"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0502077677
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="lg:top-[11%] absolute top-[9.4%] left-[45%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal6}
              onMouseLeave={closeModal6}
            />
            {isModalOpen6 && (
              <div
                onMouseEnter={openModal6}
                onMouseLeave={closeModal6}
                className="z-10 absolute transform -translate-x-[50%] -translate-y-[111%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://go.2gis.com/nyggr"
                  className="absolute text-[10px] bottom-[-20px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[120px]"
                >
                  {t('main.t9')}
                </a>
                <p className="text-[12px] font-bold">
                  Офис УГНС Первомайского района
                </p>
                <p className="text-[11px]">
                  Адрес: г. Бишкек, ул. Киевская 96, Б
                </p>
                <p className="text-[11px]">
                  {t('main.t11')}: Пн-Пт с 09-00 по 18-00
                </p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    target="_blank"
                    href="https://wa.me/996999910129"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0999 910 129(WhatsApp)
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-[10%] left-[49%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal7}
              onMouseLeave={closeModal7}
            />
            {isModalOpen7 && (
              <div
                onMouseEnter={openModal7}
                onMouseLeave={closeModal7}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[111%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://go.2gis.com/gkvo0"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[120px]"
                >
                  {t('main.t9')}
                </a>
                <p className="text-[12px] font-bold">г. Токмок</p>
                <p className="text-[11px]">
                  Адрес: г. Токмок ул Ленина 389(здание Треста), 3-этаж, каб.
                  305.
                </p>
                <p className="text-[11px]">
                  {t('main.t11')}: Пн-Пт. с 09-00 по 18-00
                </p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996755341966"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0755 341 966
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="lg:top-[8%] lg:left-[42%] absolute top-[8%] left-[43%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal8}
              onMouseLeave={closeModal8}
            />
            {isModalOpen8 && (
              <div
                onMouseEnter={openModal8}
                onMouseLeave={closeModal8}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[113%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://go.2gis.com/8yoter"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[120px]"
                >
                  {t('main.t9')}
                </a>
                <p className="text-[12px] font-bold">с. Сокулук</p>
                <p className="text-[11px]">Адрес: с. Сокулук, ул. Фрунзе</p>
                <p className="text-[11px]">{t('t11')}: с 09-00 до 18-00.</p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996707893376"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0707 893 376
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="lg:top-[8%] lg:left-[40%] absolute top-[8%] left-[41%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal9}
              onMouseLeave={closeModal9}
            />
            {isModalOpen9 && (
              <div
                onMouseEnter={openModal9}
                onMouseLeave={closeModal9}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://go.2gis.com/dl6qt"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[120px]"
                >
                  {t('main.t9')}
                </a>
                <p className="text-[12px] font-bold">г. Кара-Балта</p>
                <p className="text-[11px]">
                  Адрес: г. Кара-Балта ул. Крупская, 11.
                </p>
                <p className="text-[11px]">
                  {t('main.t11')}: Пн-Пт. с 09-00 по 18-00.
                </p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996709541208"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0709 541 208
                  </a>
                  <a
                    href="tel:+996777675175"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0777 675 175
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Чуйская область */}
        {/*  */}
        {/* Ошская область */}
        <div className="absolute top-[64%] left-[35%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal10}
              onMouseLeave={closeModal10}
            />
            {isModalOpen10 && (
              <div
                onMouseEnter={openModal10}
                onMouseLeave={closeModal10}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://go.2gis.com/ixp6a"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[120px]"
                >
                  {t('main.t9')}
                </a>
                <p className="text-[12px] font-bold">г. Ош</p>
                <p className="text-[11px]">
                  Адрес: г.ОШ ул. Масалиева, д. 100А
                </p>
                <p className="text-[11px]">
                  {t('main.t11')}: Пн-Пт. с 9-00 до 18-00 {t('main.t12')}
                </p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996778904859"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0778 904 859
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-[61.5%] left-[36.5%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal11}
              onMouseLeave={closeModal11}
            />
            {isModalOpen11 && (
              <div
                onMouseEnter={openModal11}
                onMouseLeave={closeModal11}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://go.2gis.com/dt4zo"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[120px]"
                >
                  {t('main.t9')}
                </a>
                <p className="text-[12px] font-bold">г. Кара-Суу</p>
                <p className="text-[11px]">
                  Адрес: г. Кара-Суу, ул. Жусуп Аматов 2А
                </p>
                <p className="text-[11px]">{t('t11')}: с 09-00 до 18-00</p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996556333341"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0556 333 341
                  </a>
                  <a
                    href="tel:+996709373791"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0709 373 791
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-[73%] left-[28%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal12}
              onMouseLeave={closeModal12}
            />
            {isModalOpen12 && (
              <div
                onMouseEnter={openModal12}
                onMouseLeave={closeModal12}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://goo.gl/maps/TT23beH9FmWx5dKt6"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[140px]"
                >
                  {t('main.t99')}
                </a>
                <p className="text-[12px] font-bold">г. Кызыл-Кыя</p>
                <p className="text-[11px]">
                  Адрес: г. Кызыл-Кыя, ул.Ленина, д. 6, кв 15.
                </p>
                <p className="text-[11px]">
                  {t('main.t11')}: Пн-Пт. с 9-00 до 18-00.
                </p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996772706580"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0772 706 580
                  </a>
                  <a
                    href="tel:+996552706580"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0552 706 580
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-[66%] left-[32%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal13}
              onMouseLeave={closeModal13}
            />
            {isModalOpen13 && (
              <div
                onMouseEnter={openModal13}
                onMouseLeave={closeModal13}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://goo.gl/maps/SSqjHRRkSuLttAGa9"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[140px]"
                >
                  Перейти в Goole map
                </a>
                <p className="text-[12px] font-bold">с. Араван</p>
                <p className="text-[11px]">
                  Адрес: Ошская область, Араванский район, с.Араван,
                  ул.Тошматова, 26
                </p>
                <p className="text-[11px]">
                  {t('main.t11')}: Пн-Пт. с 9-00 до 18-00
                </p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996552500058"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0552 500 058
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-[70%] left-[34%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal14}
              onMouseLeave={closeModal14}
            />
            {isModalOpen14 && (
              <div
                onMouseEnter={openModal14}
                onMouseLeave={closeModal14}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://goo.gl/maps/fzpobxee3wreJwhx8"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[140px]"
                >
                  {t('main.t99')}
                </a>
                <p className="text-[12px] font-bold">Ноокат</p>
                <p className="text-[11px]">
                  Адрес: Ноокат ул. О. Солтобаева 18
                </p>
                <p className="text-[11px]">{t('t11')}: с 09-00 до 18-00</p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996558151516"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0558 151 516
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-[63%] left-[34%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal15}
              onMouseLeave={closeModal15}
            />
            {isModalOpen15 && (
              <div
                onMouseEnter={openModal15}
                onMouseLeave={closeModal15}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://goo.gl/maps/g52aaZEzMYan2Dv18"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[140px]"
                >
                  {t('main.t99')}
                </a>
                <p className="text-[12px] font-bold">г. Ош (ЦОП)</p>
                <p className="text-[11px]">
                  Адрес: г. Ош, пр. Масалиева, 75 (здание ЦОП)
                </p>
                <p className="text-[11px]">
                  {t('main.t11')}: Пн-Пт. с 9-00 до 18-00 {t('main.t12')}.
                </p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996502644903"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0502 644 903
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Ошская область */}
        {/*  */}
        {/* Баткенская область */}
        <div className="absolute top-[75%] left-[24%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal16}
              onMouseLeave={closeModal16}
            />
            {isModalOpen16 && (
              <div
                onMouseEnter={openModal16}
                onMouseLeave={closeModal16}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://goo.gl/maps/koM2oiqqseyWaMX76"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[140px]"
                >
                  {t('main.t99')}
                </a>
                <p className="text-[12px] font-bold">Кадамжай</p>
                <p className="text-[11px]">
                  Адрес: Кадамжай, ул. А. Жалилова3/38 (ориентир ЭкоИсламик
                  Банк)
                </p>
                <p className="text-[11px]">{t('t11')}: с 09-00 до 18-00</p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996773365365"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0773 365 365
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-[80%] left-[14%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal17}
              onMouseLeave={closeModal17}
            />
            {isModalOpen17 && (
              <div
                onMouseEnter={openModal17}
                onMouseLeave={closeModal17}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://goo.gl/maps/d5wtycUUqWgkdpNd7"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[140px]"
                >
                  {t('main.t99')}
                </a>
                <p className="text-[12px] font-bold">г. Баткен</p>
                <p className="text-[11px]">Адрес: г. Баткен</p>
                <p className="text-[11px]">{t('t11')} с 09-00 до 18-00</p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996222187657"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0222 187 657
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-[80%] left-[5%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal18}
              onMouseLeave={closeModal18}
            />
            {isModalOpen18 && (
              <div
                onMouseEnter={openModal18}
                onMouseLeave={closeModal18}
                className="lg:-translate-x-[30%] z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://goo.gl/maps/L8ey4bmGBWP73tN79"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[140px]"
                >
                  {t('main.t99')}
                </a>
                <p className="text-[12px] font-bold">г. Раззаков</p>
                <p className="text-[11px]">
                  Адрес: г. Раззаков, ул. Разакова, 31/1.
                </p>
                <p className="text-[11px]">
                  {' '}
                  {t('main.t11')}: с 09-00 до 18-00.
                </p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+9960770633330"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0770 633 330
                  </a>
                  <a
                    href="tel:+996999633330"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0999 633 330
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Баткенская область */}
        {/*  */}
        {/* Джалал-абадская область */}
        <div className="absolute top-[54%] left-[39.5%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal19}
              onMouseLeave={closeModal19}
            />
            {isModalOpen19 && (
              <div
                onMouseEnter={openModal19}
                onMouseLeave={closeModal19}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://goo.gl/maps/u7FiFgePShPHgDx3A"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[140px]"
                >
                  {t('main.t99')}
                </a>
                <p className="text-[12px] font-bold">г. Узген</p>
                <p className="text-[11px]">Адрес: г. Узген, ул. Ленина, 296</p>
                <p className="text-[11px]">{t('t11')}: с 09-00 до 18-00</p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996770290767"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0770 290 767
                  </a>
                  <a
                    href="tel:+996550290767"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0550 290 767
                  </a>
                  <a
                    href="tel:+996707290767"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0707 290 767
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-[50%] left-[32.5%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal20}
              onMouseLeave={closeModal20}
            />
            {isModalOpen20 && (
              <div
                onMouseEnter={openModal20}
                onMouseLeave={closeModal20}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://goo.gl/maps/8qMzST3KHiNGwhj46"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[140px]"
                >
                  {t('main.t99')}
                </a>
                <p className="text-[12px] font-bold">г. Жалал-Абад</p>
                <p className="text-[11px]">
                  Адрес: г. Жалал-Абад, ул.Ленина, д. 34, Дом Быта 4-этаж,
                  кабинет-8
                </p>
                <p className="text-[11px]">
                  {t('main.t11')}: Пн-Пт. с 9-00 до 18-00.
                </p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996709516737"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0709 516 737
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-[44%] left-[18%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal21}
              onMouseLeave={closeModal21}
            />
            {isModalOpen21 && (
              <div
                onMouseEnter={openModal21}
                onMouseLeave={closeModal21}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://goo.gl/maps/FqJZgjWHNgUr2gdS6"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[140px]"
                >
                  {t('main.t99')}
                </a>
                <p className="text-[12px] font-bold">с. Ала-Бука</p>
                <p className="text-[11px]">
                  Адрес: с. Ала-Бука, ул. С. Ибраимова, 56,
                </p>
                <p className="text-[11px]">
                  {t('main.t11')}: с 09-00 до 18-00, {t('main.t12')}.
                </p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996555673717"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0555 673 717
                  </a>
                  <a
                    href="tel:+996704121747"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0704 121 747
                  </a>
                  <a
                    href="tel:+996554555547"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0554 555 547
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Джалал-абадская область */}
        {/*  */}
        {/* Талаская область */}
        <div className="absolute top-[24%] left-[31%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal22}
              onMouseLeave={closeModal22}
            />
            {isModalOpen22 && (
              <div
                onMouseEnter={openModal22}
                onMouseLeave={closeModal22}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://goo.gl/maps/24na4xBnbGjKh9sW9"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[140px]"
                >
                  {t('main.t99')}
                </a>
                <p className="text-[12px] font-bold">г. Кара-Куль</p>
                <p className="text-[11px]">
                  Адрес: Жалал-Абадская обл., город Кара-Куль, ул. Сабирова, 23а
                  (находится в здании УГНС г.Кара-Куль)
                </p>
                <p className="text-[11px]">{t('t11')}: с 09-00 до 18-00</p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996550985866"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0550 98 58 66
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-[16%] left-[23%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal23}
              onMouseLeave={closeModal23}
            />
            {isModalOpen23 && (
              <div
                onMouseEnter={openModal23}
                onMouseLeave={closeModal23}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://goo.gl/maps/dU4a61h9AZ6jtSVK8"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[140px]"
                >
                  {t('main.t99')}
                </a>
                <p className="text-[12px] font-bold">г. Талас</p>
                <p className="text-[11px]">
                  Адрес: г. Талас, ул. Бердике-Баатыра, д. 257,
                </p>
                <p className="text-[11px]">
                  {t('main.t11')}: Пн-Пт. с 9-00 до 18-00 {t('main.t12')}.
                </p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996779485876"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0779 485 876
                  </a>
                  <a
                    href="tel:+996550971201"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0 550 971 201
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Талаская область */}
        {/*  */}
        {/* Нарынская область */}
        <div className="absolute top-[48%] left-[64%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal24}
              onMouseLeave={closeModal24}
            />
            {isModalOpen24 && (
              <div
                onMouseEnter={openModal24}
                onMouseLeave={closeModal24}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://goo.gl/maps/i2BpT3hzodBrHqEG8"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[140px]"
                >
                  {t('main.t99')}
                </a>
                <p className="text-[12px] font-bold">г. Нарын</p>
                <p className="text-[11px]">
                  Адрес: г. Нарын, ул. Ленина, д. 76,(здание Профсоюза)
                </p>
                <p className="text-[11px]">{t('t11')}: с 9.00 по 18.00</p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996705484745"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0705 484 745
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-[30%] left-[57%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal25}
              onMouseLeave={closeModal25}
            />
            {isModalOpen25 && (
              <div
                onMouseEnter={openModal25}
                onMouseLeave={closeModal25}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://goo.gl/maps/c4gFwkinWieSQWkb8"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[140px]"
                >
                  {t('main.t99')}
                </a>
                <p className="text-[12px] font-bold">с. Кочкор</p>
                <p className="text-[11px]">
                  Адрес: с. Кочкор, ул. И. Омурова, д. 30,
                </p>
                <p className="text-[11px]">{t('t11')}: с 09-00 до 18-00</p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996708501411"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0708 501 411
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Нарынская область */}
        {/*  */}
        {/* Ыссыкулская область */}
        <div className="absolute top-[16.3%] left-[60.3%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal26}
              onMouseLeave={closeModal26}
            />
            {isModalOpen26 && (
              <div
                onMouseEnter={openModal26}
                onMouseLeave={closeModal26}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://goo.gl/maps/msmGYKUCMsr9jGDNA"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[140px]"
                >
                  {t('main.t99')}
                </a>
                <p className="text-[12px] font-bold">г. Балыкчы</p>
                <p className="text-[11px]">
                  Адрес: г. Балыкчы, ул. Кулакунова 47, (ТД Береке 1-этаж) 366.
                </p>
                <p className="text-[11px]">
                  {t('main.t11')}: Пн-Сб с 09-00 по 18-00
                </p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996706225386"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0706 225 386
                  </a>
                  <a
                    href="tel:+996990336366"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0990 336 366
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-[11.6%] left-[66.3%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal27}
              onMouseLeave={closeModal27}
            />
            {isModalOpen27 && (
              <div
                onMouseEnter={openModal27}
                onMouseLeave={closeModal27}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://goo.gl/maps/msmGYKUCMsr9jGDNA"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[140px]"
                >
                  {t('main.t99')}
                </a>
                <p className="text-[12px] font-bold">г. Чолпон-Ата</p>
                <p className="text-[11px]">
                  Адрес: г. Чолпон-Ата ул. Советская улица, 1Б,
                </p>
                <p className="text-[11px]">
                  {t('main.t11')} Пн-Пт с 9:00 до 18:00 без перерыва
                </p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996502890005"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0502 890 005
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="absolute top-[12%] left-[73.3%]">
          <div className="relative">
            <img
              src="/assets/geotag.svg"
              alt="geotag"
              className="z-0 cursor-pointer"
              onMouseEnter={openModal28}
              onMouseLeave={closeModal28}
            />
            {isModalOpen28 && (
              <div
                onMouseEnter={openModal28}
                onMouseLeave={closeModal28}
                className="z-10 absolute  transform -translate-x-[50%] -translate-y-[112%] p-3 border-orange-400 border-2 rounded-md border-spacing-14  bg-white w-[200px]"
              >
                <a
                  target="_blank"
                  href="https://goo.gl/maps/msmGYKUCMsr9jGDNA"
                  className="absolute text-[10px] bottom-[-10px] text-white bg-[#FF4012] left-[140px] p-2 text-center border-orange-400 border-2 rounded-md w-[140px]"
                >
                  {t('main.t99')}
                </a>
                <p className="text-[12px] font-bold">г. Каракол</p>
                <p className="text-[11px]">
                  Адрес: г.Каракол ул. Арбатская 16, Магазин (Ай-Көл) вход со
                  двора ГНС Каракол.
                </p>
                <p className="text-[11px]">
                  {t('main.t11')}: Пн-Пт. с 9-00 до 18-00
                </p>
                <div className="flex flex-col justify-start">
                  <span className="text-[12px]">Телефоны :</span>
                  <a
                    href="tel:+996555949457"
                    className="text-[12px] hover:text-blue-600"
                  >
                    0555 949 457
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Ыссыкулская область */}
      </div>
    </div>
  );
};

export default MapCom;
