import { db, storage } from '../../firebaseConfig';
import { useEffect, useState } from 'react';
import Papa from 'papaparse'; 
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Paper from '@mui/material/Paper';
import { TextareaAutosize } from '@mui/base';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Alert, Snackbar } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2
};

const NewsList = () => {
  const [value, setValue] = useState('1');
  const [isNewsDataRu, setIsNewsDataRu] = useState(false);
  const [isNewsDataKg, setIsNewsDataKg] = useState(false);
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [h1, setH1] = useState('');
  const [h2, setH2] = useState('');
  const [p1, setP1] = useState('');
  const [p2, setP2] = useState('');
  const [img, setImg] = useState('');
  const [newsData, setNewsData] = useState([]);
  const [newsData2, setNewsData2] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQuery2, setSearchQuery2] = useState('');
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // Стягивание
   useEffect(() => {
    const unsubscribe = db.collection('newsDataRu').onSnapshot((snapshot) => {
      const newsDataList = [];
      snapshot.forEach(async(doc) => {
        newsDataList.push({ id: doc.id, ...doc.data() });
      });
      setNewsData(newsDataList);
    });

    return async () => unsubscribe();
  }, []);
  useEffect(() => {
    const unsubscribe = db.collection('newsDataKg').onSnapshot((snapshot) => {
      const newsDataList2 = [];
      snapshot.forEach(async(doc) => {
        newsDataList2.push({ id: doc.id, ...doc.data() });
      });
      setNewsData2(newsDataList2);
    });

    return async () => unsubscribe();
  }, []);
  // Удаление из списка
  const handleDelete = async (id) => {
    try {
      await db.collection('newsDataRu').doc(id).delete();
      setOpen2(true);
      setTimeout(() => handleClose2(), 3000)
    } catch (error) {
      setOpen3(true);
      setTimeout(() => handleClose3(), 3000)
      console.error('Delete error:', error);
    }
  };
    const handleDelete2 = async (id) => {
    try {
      await db.collection('newsDataKg').doc(id).delete();
      setOpen2(true);
      setTimeout(() => handleClose2(), 3000)
    } catch (error) {
      setOpen3(true);
      setTimeout(() => handleClose3(), 3000)
      console.error('Delete error:', error);
    }
  };
  const handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen2(false);
  };

  const handleClose3 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen2(false);
  };
  // Фильтрация
  const filteredData = newsData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  const filteredData2 = newsData2.filter((item) =>
  Object.values(item).some((value) =>
    value.toString().toLowerCase().includes(searchQuery2.toLowerCase())
  )
);
  // Экспорт в excel(csv)
  const handleExportCSV = () => {
    const csvData = filteredData.map((item) => ({
      ...item,
      time: item.submittedAt.toDate().toLocaleString()
    }));
  
    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'ubr-news.csv';
    a.click();
    URL.revokeObjectURL(url);
  };
  const handleExportCSV2 = () => {
    const csvData2 = filteredData2.map((item) => ({
      ...item,
      time: item.submittedAt.toDate().toLocaleString()
    }));
  
    const csv = Papa.unparse(csvData2);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'ubr-news.csv';
    a.click();
    URL.revokeObjectURL(url);
  };
  ///////////////////////////////////////////////////////////////////
  const handleSubmit = async () => {
    const currentDate = new Date();
    // Create a reference to Firebase Storage where you want to save the image
    const storageRef = storage.ref().child(`images/${currentDate.getTime()}`);
  
    // Get the selected file from the input
    const fileInput = document.querySelector('input[name="img"]');
    const imageFile = fileInput.files[0];
  
    // Upload the image to Firebase Storage
    const snapshot = await storageRef.put(imageFile);
  
    // Get the URL of the uploaded image
    const imageURL = await snapshot.ref.getDownloadURL();
    
    try {
      const currentDate = new Date();
      const dataToPush = {
        h1,
        p1,
        h2,
        p2,
        img: imageURL,
        submittedAt: currentDate
      };
      // Check the checkboxes and push data accordingly
      if (isNewsDataRu) {
        await db.collection('newsDataRu').add(dataToPush);
      }
      if (isNewsDataKg) {
        await db.collection('newsDataKg').add(dataToPush);
      }
    } catch (e) {
      console.log('Submit error:', e);
    }
  };  
  return (
    <>
      <div className="flex items-center justify-center">
        <Button onClick={handleOpen}><h1 className="rounded-md bg-[#FF4012] p-3 font-semibold text-white hover:bg-red-500">{t('admin.btn')}</h1></Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="w-[346px] border rounded-lg flex flex-col gap-5 px-5 py-5">
              <h1 className="text-xl font-bold">{t('admin.newsTitle')}</h1>

              <div className="flex justify-between">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="newsDataRuCheckbox"
                    checked={isNewsDataRu}
                    onChange={() => setIsNewsDataRu(!isNewsDataRu)}
                  />
                  <label htmlFor="newsDataRuCheckbox" className="ml-2">
                    Русский
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="newsDataKgCheckbox"
                    checked={isNewsDataKg}
                    onChange={() => setIsNewsDataKg(!isNewsDataKg)}
                  />
                  <label htmlFor="newsDataKgCheckbox" className="ml-2">
                    Кыргызский
                  </label>
                </div>
              </div>
              
              <div className="flex flex-col gap-2 text-[#000000] opacity-50">
                <label htmlFor="title">{t('admin.news1')}</label>
                <input
                  name="title"
                  required
                  className="border rounded-xl h-9 border-t-0.5 border-gray-300"
                  type="text"
                  value={h1}
                  onChange={(e) => setH1(e.target.value)}
                />
                <label htmlFor="pretitle">{t('admin.news2')}</label>
                <input
                  required
                  name="pretitle"
                  className="border rounded-xl border-t-0.5 border-gray-300 h-9"
                  type="text"
                  value={p1}
                  onChange={(e) => setP1(e.target.value)}
                />
                <label htmlFor="info">{t('admin.news3')}</label>
                <textarea
                  style={{ resize: 'none' }}
                  required
                  name="info"
                  className="border rounded-xl h-28 border-t-0.5 border-gray-300 items-start"
                  type="file"
                  value={h2}
                  onChange={(e) => setH2(e.target.value)}
                />
                <label htmlFor="info2">{t('admin.news4')}</label>
                <textarea
                  required
                  name="info2"
                  className="border rounded-xl h-28 border-t-0.5 border-gray-300 items-start"
                  type="text"
                  value={p2}
                  onChange={(e) => setP2(e.target.value)}
                />
                <label htmlFor="img">{t('admin.news5')}</label>
                <input
                  required
                  name="img"
                  className="h-9"
                  type="file"
                  value={img}
                  onChange={(e) => setImg(e.target.value)}
                />
              </div>
              <button
                type="button"
                className="items-center rounded-md bg-[#FF4012] py-3 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
                onClick={handleSubmit}
              >
                Создать
              </button>
            </div>
          </Box>
        </Modal>
      </div>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', alignItems: "center", justifyContent: "center", display: "flex" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Русский" value="1" />
              <Tab label="Кыргызский" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <TableContainer component={Paper}  className="p-5">
              <div className="flex h-10 gap-4">
                <input 
                  value={searchQuery} 
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="max-w-[440px] border rounded-xl p-2"
                  placeholder="Поиск..."
                />
                <button onClick={handleExportCSV} title="Скачать данные в Exsel">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#29883a" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                  </svg>
                  <p className="text-[#29883a]">CSV</p>
                </button>
              </div>
              <Table className="mt-5">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Изображение</TableCell>
                    <TableCell align="left">Заголовок</TableCell>
                    <TableCell align="left">Подзаголовок</TableCell>
                    <TableCell align="left">Информация</TableCell>
                    <TableCell align="left">Доп. Информация</TableCell>
                    <TableCell align="left">Дата/Время</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell><img src={item.img ? item.img : "Неизвество"} alt="img" width={200} className="rounded-xl" /></TableCell>
                      <TableCell fullWidth>
                        <TextareaAutosize>
                          {item.h1 ? item.h1 : "Неизвество"}
                        </TextareaAutosize>
                      </TableCell>
                      <TableCell fullWidth>
                        <TextareaAutosize>
                          {item.p1 ? item.p1 : "Неизвество"}
                        </TextareaAutosize>
                      </TableCell>
                      <TableCell fullWidth>
                        <TextareaAutosize>
                          {item.h2 ? item.h2 : "Неизвество"}  
                        </TextareaAutosize>
                      </TableCell>
                      <TableCell fullWidth>
                        <TextareaAutosize>
                          {item.p2 ? item.p2 : "Неизвество"}
                        </TextareaAutosize>
                      </TableCell>
                      <TableCell>
                        {item.submittedAt ? item.submittedAt.toDate().toLocaleString() : 'N/A'}
                      </TableCell>
                      <TableCell>
                        <button variant="danger" onClick={() => handleDelete(item.id)} title="Удалить">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                          </svg>
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value="2">
            <TableContainer component={Paper}  className="p-5">
              <div className="flex h-10 gap-4">
                <input 
                  value={searchQuery} 
                  onChange={(e) => setSearchQuery2(e.target.value)}
                  className="max-w-[440px] border rounded-xl p-2"
                  placeholder="Поиск..."
                />
                <button onClick={handleExportCSV2} title="Скачать данные в Exsel">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#29883a" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                  </svg>
                  <p className="text-[#29883a]">CSV</p>
                </button>
              </div>
              <Table className="mt-5">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Изображение</TableCell>
                    <TableCell align="left">Заголовок</TableCell>
                    <TableCell align="left">Подзаголовок</TableCell>
                    <TableCell align="left">Информация</TableCell>
                    <TableCell align="left">Доп. Информация</TableCell>
                    <TableCell align="left">Дата/Время</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData2.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell><img src={item.img ? item.img : "Неизвество"} alt="img" width={200} className="rounded-xl" /></TableCell>
                      <TableCell fullWidth>
                        <TextareaAutosize>
                          {item.h1 ? item.h1 : "Неизвество"}
                        </TextareaAutosize>
                      </TableCell>
                      <TableCell fullWidth>
                        <TextareaAutosize>
                          {item.p1 ? item.p1 : "Неизвество"}
                        </TextareaAutosize>
                      </TableCell>
                      <TableCell fullWidth>
                        <TextareaAutosize>
                          {item.h2 ? item.h2 : "Неизвество"}  
                        </TextareaAutosize>
                      </TableCell>
                      <TableCell fullWidth>
                        <TextareaAutosize>
                          {item.p2 ? item.p2 : "Неизвество"}
                        </TextareaAutosize>
                      </TableCell>
                      <TableCell>
                        {item.submittedAt ? item.submittedAt.toDate().toLocaleString() : 'N/A'}
                      </TableCell>
                      <TableCell>
                        <button variant="danger" onClick={() => handleDelete2(item.id)} title="Удалить">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                          </svg>
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </TabContext>
        <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
          <Alert onClose={handleClose2} severity="success" sx={{ width: '100%' }}>
            Успешно удалено!
          </Alert>
        </Snackbar>
        <Snackbar open={open3} autoHideDuration={6000} onClose={handleClose3}>
          <Alert onClose={handleClose3} severity="error" sx={{ width: '100%' }}>
            Ошибка удаление!
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default NewsList;
