import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAZqJc7FXlWQ94G1mpYqKgZgrGRmse2hWM",
    authDomain: "ubr-host.firebaseapp.com",
    databaseURL: "https://ubr-host-default-rtdb.firebaseio.com",
    projectId: "ubr-host",
    storageBucket: "ubr-host.appspot.com",
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

const storage = firebase.storage()
const db = firebase.firestore();
const auth = firebase.auth();
export { db,auth,storage };
